export const TASK_STATE_NEW = 1;
export const TASK_STATE_CANCELED = 5;
export const TASK_STATE_POSTPONED = 6;
export const TASK_STATE_EXECUTING = 10;
export const TASK_STATE_REQUESTER_MODIFIED = 12;
export const TASK_STATE_COMPLETED = 20;
export const TASK_STATE_FINISHED = 40;

export const DEFAULT_CLIENT_PHOTO_URL = 'assets/img/images/box_user.png';
export const DEFAULT_CONSULTANT_PHOTO_URL = 'assets/img/images/box.png';
export const DEFAULT_UNKNOWN_USER_PHOTO_URL = 'assets/img/images/box.png';

export const BUSENA_NAUJA = 1;
export const BUSENA_ATMESTA = 2;
export const BUSENA_ATIDETA = 4;
export const BUSENA_VYKDOMA = 8;
export const BUSENA_V_PATIKSLINTA = 16;
export const BUSENA_U_PATIKSLINTA = 32;
export const BUSENA_ATLIKTA = 64;
export const BUSENA_NEPRIIMTA = 128;
export const BUSENA_BAIGTA = 256;
export const BUSENA_PASKIRSTYTA = 512;
export const BUSENA_PRIIMTA = 1024;
export const BUSENA_GRAZINTA = 2048;
export const BUSENA_LAIMETA = 4096;
export const BUSENA_TESTAVIMAS = 8192;

export const MY_TASKS_TYPE = 0;
export const TASKS_FOR_ME_TYPE = 1;
export const TASKS_FOR_MY_GROUP_TYPE = 2;
export const REQUESTS_FOR_ME_TYPE = 3;
export const REQUESTS_FOR_MY_GROUP_TYPE = 4;

export const TASKS_TRANSFERS_TYPE = false;
export const REQUESTS_TRANSFERS_TYPE = true;
