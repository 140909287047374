
import { filter, catchError } from 'rxjs/operators';

import { empty as observableEmpty, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { DpaControlsComponent } from "../jobs/dpaControls/dpaControls.component";
import { TplService } from "../../services/tpl.service";
import { ModalsService } from "../../services/modals.service";
import { TASK_STATE_NEW, TASK_STATE_REQUESTER_MODIFIED } from "../../../core/constants";
import { PRIORITY_ABBR, PRIORITY_COLORS, PRIORITY_TOOLTIPS } from "../../taskboard";
import { compareToToday, isEditableTask, remove } from "../../../template/shared/proto/helpers";
import { getDpaEmailText, printDpa, DPA_NAME, getDpaEmailTextV2 } from "../../common/dpa";
import { FilesBackend } from "../../../backend/files.backend";
import { TaskboardSidebarService } from "../../services/taskboardSidebar.service";
import { OPEN_JOBS_TAB, UPDATE_TASKBOARD_LIST } from "../../../state/state";
import { StateService } from "../../../services/state/stateService";
import { NgxPermissionsService } from "ngx-permissions";
import { UpdateRequestStateComponent } from "../modals/update-request-state/updateRequestState.component";
import { DEFAULT_MODAL_OPTS, TASK_CHILD_ENTERED, TASK_CLIENT_COMMENTED, TASK_EMPLOYEE_COMMENTED, TASK_STATE_CHANGED } from "../../common/constants";
import { TimerService } from "../../../template/shared/services/timer/timer-service";
import { TimerState } from "../../../template/shared/services/timer/timer";
import { TimerDialogComponent } from "../../../template/shared/components/timer/timer-dialog/timer-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { JobFormModel } from "../../models/job-form";
import { EntityUpdated } from "../../../core/api/response/entity-updated";
import { TasksBackend, TaskStateChanged } from "../../../backend/tasks.backend";
import { TaskboardDataService } from '../../../taskboard/services/taskboard-data.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SendDpaModalComponent } from '../../../template/shared/components/modals/send-dpa-modal/send-dpa-modal.component';
import { DpaBackend } from '../../../backend/dpa.backend';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActionModalComponent } from '../actions-modal/action-modal.component';
import { NearestActionFormComponent } from '../modals/nearest-action-component/nearestActionForm.component';
import { DATE_WITH_TIME, SHORT_DATE } from '../../../core/time';
import { DPA, REQUESTS, REVIEW, TASKS } from '../../../taskboard/taskboard.help';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SelectedPartnerService } from '../../../services/partner/selectedPartner.service';
import { CommentsModalComponent } from '../../../template/shared/components/modals/comments-modal/comments-modal.component';
import { MarketingParticipansListModalComponent } from '../modals/marketing-participans-list/marketing-participans-list.component';
import { MarketingCampaignBackend } from '../../../backend/marketing-campaign.backend';
import * as XLSX from 'xlsx';
import { TransferFormComponent } from '../modals/transfer-form/transfer-form.component';
import { UpdateRequestTypeComponent } from '../modals/update-request-type/update-request-type.component';
import { UpdateTaskPartnerComponent } from '../modals/update-task-partner/update-task-partner.component';

const today = (today, d) => d.getFullYear() == today.getFullYear() && d.getMonth() == today.getMonth() && d.getDate() == today.getDate();

@Component({
  selector: 'taskboard-templates',
  templateUrl: './taskboard-templates.component.html',
  styleUrls: ['./taskboard-templates.component.scss']
})
export class TaskboardTemplatesComponent implements OnInit, OnDestroy {

  shortDate = SHORT_DATE;
  dateWithTime = DATE_WITH_TIME;
  //TEMPLATES
  @ViewChild('dpaCodeTpl', { static: true }) dpaCodeTpl: TemplateRef<any>;
  @ViewChild('isPaidTpl', { static: true }) isPaidTpl: TemplateRef<any>;
  @ViewChild('descriptionTpl', { static: true }) descriptionTpl: TemplateRef<any>;
  @ViewChild('textTpl', { static: true }) textTpl: TemplateRef<any>;
  @ViewChild('deadlineTpl', { static: true }) deadlineTpl: TemplateRef<any>;
  @ViewChild('stateTpl', { static: true }) stateTpl: TemplateRef<any>;
  @ViewChild('priorityTpl', { static: true }) priorityTpl: TemplateRef<any>;
  @ViewChild('reactionTpl', { static: true }) reactionTpl: TemplateRef<any>;
  @ViewChild('lastStateChangeTpl', { static: true }) lastStateChangeTpl: TemplateRef<any>;
  @ViewChild('jobButtonsTpl', { static: true }) jobButtonsTpl: TemplateRef<any>;
  @ViewChild('dpaOptionsTpl', { static: true }) dpaOptionsTpl: TemplateRef<any>;
  @ViewChild('delegateEmailTpl', { static: true }) delegateEmailTpl: TemplateRef<any>;
  @ViewChild('isDpaFinishedTpl', { static: true }) isDpaFinishedTpl: TemplateRef<any>;
  @ViewChild('nearestActionTpl', { static: true }) nearestActionTpl: TemplateRef<any>;
  @ViewChild('stateForUserTpl', { static: true }) stateForUserTpl: TemplateRef<any>;
  @ViewChild('taskCodeTpl', { static: true }) taskCodeTpl: TemplateRef<any>;
  @ViewChild('projectsLinkTpl', { static: true }) projectsLinkTpl: TemplateRef<any>;
  @ViewChild('dateTpl', { static: true }) dateTpl: TemplateRef<any>;
  @ViewChild('endDateTpl', { static: true }) endDateTpl: TemplateRef<any>;
  @ViewChild('projectEndDateTpl', { static: true }) projectEndDateTpl: TemplateRef<any>;
  @ViewChild('startDateTpl', { static: true }) startDateTpl: TemplateRef<any>;
  @ViewChild('shortDateTpl', { static: true }) shortDateTpl: TemplateRef<any>;
  @ViewChild('dateEnteredTpl', { static: true }) dateEnteredTpl: TemplateRef<any>;
  @ViewChild('dateEnteredShortTpl', { static: true }) dateEnteredShortTpl: TemplateRef<any>;
  @ViewChild('lastStateChangeCellTpl', { static: true }) lastStateChangeCellTpl: TemplateRef<any>;
  @ViewChild('sumWithoutVATDpaListTpl', { static: true }) sumWithoutVATDpaListTpl: TemplateRef<any>;
  @ViewChild('sumWithoutVATProjectsListTpl', { static: true }) sumWithoutVATProjectsListTpl: TemplateRef<any>;
  @ViewChild('isFavoriteTpl', { static: true }) isFavoriteTpl: TemplateRef<any>;
  @ViewChild('isFavoriteCampaignsTpl', { static: true }) isFavoriteCampaignsTpl: TemplateRef<any>;
  @ViewChild('DPAMailStateTpl', { static: true }) DPAMailStateTpl: TemplateRef<any>;
  @ViewChild('undoSeenTpl', { static: true }) undoSeenTpl: TemplateRef<any>;
  @ViewChild('executorTpl', { static: true }) executorTpl: TemplateRef<any>;
  @ViewChild('executorEditableTpl', { static: true }) executorEditableTpl: TemplateRef<any>;
  @ViewChild('participansButtonsTpl', { static: true }) participansButtonsTpl: TemplateRef<any>;
  @ViewChild('gotoCampaignsEditTpl', { static: true }) gotoCampaignsEditTpl: TemplateRef<any>;
  @ViewChild('taskTypeTpl', { static: true }) taskTypeTpl: TemplateRef<any>;
  @ViewChild('partnerTpl', { static: true }) partnerTpl: TemplateRef<any>;
  @ViewChild('isRequestTpl', { static: true }) isRequestTpl: TemplateRef<any>;
  @ViewChild('ownersTextTpl', { static: true }) ownersTextTpl: TemplateRef<any>;
  @ViewChild('jobsDescriptionAltTpl', { static: true }) jobsDescriptionAltTpl: TemplateRef<any>;
  @ViewChild('dateStartTpl', { static: true }) dateStartTpl: TemplateRef<any>;
  @ViewChild('actualConsumptionTpl', { static: true }) actualConsumptionTpl: TemplateRef<any>;
  @ViewChild('isFavoriteHeaderTpl', { static: true }) isFavoriteHeaderTpl: TemplateRef<any>;
  @ViewChild('isProjectHeaderTpl', { static: true }) isProjectHeaderTpl: TemplateRef<any>;
  @ViewChild('undoSeenHeaderTpl', { static: true }) undoSeenHeaderTpl: TemplateRef<any>;
  @ViewChild('stateForUserHeaderTpl', { static: true }) stateForUserHeaderTpl: TemplateRef<any>;

  private _now = new Date();
  private _downloadingDpas = [];
  private _printingDpas = [];
  private _openingDpas = [];
  public _isSending: any = false;

  _priorityColors = PRIORITY_COLORS;
  _priorityAbbr = PRIORITY_ABBR;
  _priorityTooltips = PRIORITY_TOOLTIPS;

  _timerStates = TimerState;

  _NEW_CHILD = TASK_CHILD_ENTERED;
  _CLIENT_COMMENT = TASK_CLIENT_COMMENTED;
  private _subscriptions: Array<Subscription> = [];

  faTimes = faTimes;
  faCheck = faCheck;
  userSelected: boolean = false;

  compareToToday = compareToToday;

  constructor(private _tplService: TplService,
    private _modalsService: ModalsService,
    private _stateService: StateService,
    private _filesBackend: FilesBackend,
    private _chg: ChangeDetectorRef,
    public _permissionsService: NgxPermissionsService,
    private _taskboardSidebar: TaskboardSidebarService,
    private taskboardDataService: TaskboardDataService,
    private _timerService: TimerService,
    public dialog: MatDialog,
    private _dpaBackend: DpaBackend,
    private http: HttpClient,
    private _router: Router,
    private _tasksBackend: TasksBackend,
    private selectedPartnerService: SelectedPartnerService,
    private marketingCampaignBackend: MarketingCampaignBackend,
  ) { }

  ngOnInit() {
    this._registerTpls();


    this._subscriptions.push(this.selectedPartnerService.selectedPartner.subscribe((partner) => {
      if (partner.isUser || partner.isEmpty) {
        const user = partner.partner;
        if (user && user.name && user.name.length > 0) {
          this.userSelected = true;
        } else {
          this.userSelected = false;
        }
      }
    }));
  }

  private _registerTpls() {
    const tpl = this._tplService;
    tpl.dpaCodeTpl = this.dpaCodeTpl;
    tpl.isPaidTpl = this.isPaidTpl;
    tpl.descriptionTpl = this.descriptionTpl;
    tpl.deadlineTpl = this.deadlineTpl;
    tpl.stateTpl = this.stateTpl;
    tpl.priorityTpl = this.priorityTpl;
    tpl.reactionTpl = this.reactionTpl;
    tpl.lastStateChangeTpl = this.lastStateChangeTpl;
    tpl.jobButtonsTpl = this.jobButtonsTpl;
    tpl.dpaOptionsTpl = this.dpaOptionsTpl;
    tpl.delegateEmailTpl = this.delegateEmailTpl;
    tpl.isDpaFinishedTpl = this.isDpaFinishedTpl;
    tpl.nearestActionTpl = this.nearestActionTpl;
    tpl.stateForUserTpl = this.stateForUserTpl;
    tpl.taskCodeTpl = this.taskCodeTpl;
    tpl.projectsLinkTpl = this.projectsLinkTpl;
    tpl.dateTpl = this.dateTpl;
    tpl.endDateTpl = this.endDateTpl;
    tpl.projectEndDateTpl = this.projectEndDateTpl;
    tpl.startDateTpl = this.startDateTpl;
    tpl.shortDateTpl = this.shortDateTpl;
    tpl.dateEnteredTpl = this.dateEnteredTpl;
    tpl.dateEnteredShortTpl = this.dateEnteredShortTpl;
    tpl.lastStateChangeCellTpl = this.lastStateChangeCellTpl;
    tpl.sumWithoutVATDpaListTpl = this.sumWithoutVATDpaListTpl;
    tpl.sumWithoutVATProjectsListTpl = this.sumWithoutVATProjectsListTpl;
    tpl.isFavoriteTpl = this.isFavoriteTpl;
    tpl.isFavoriteCampaignsTpl = this.isFavoriteCampaignsTpl;
    tpl.DPAMailStateTpl = this.DPAMailStateTpl;
    tpl.undoSeenTpl = this.undoSeenTpl;
    tpl.executorTpl = this.executorTpl;
    tpl.executorEditableTpl = this.executorEditableTpl;
    tpl.gotoCampaignsEditTpl = this.gotoCampaignsEditTpl;
    tpl.textTpl = this.textTpl;
    tpl.participansButtonsTpl = this.participansButtonsTpl;
    tpl.taskTypeTpl = this.taskTypeTpl;
    tpl.partnerTpl = this.partnerTpl;
    tpl.isRequestTpl = this.isRequestTpl;
    tpl.ownersTextTpl = this.ownersTextTpl;
    tpl.actualConsumptionTpl = this.actualConsumptionTpl;
    tpl.jobsDescriptionAltTpl = this.jobsDescriptionAltTpl;
    tpl.dateStartTpl = this.dateStartTpl;
    tpl.isFavoriteHeaderTpl = this.isFavoriteHeaderTpl;
    tpl.isProjectHeaderTpl = this.isProjectHeaderTpl;
    tpl.undoSeenHeaderTpl = this.undoSeenHeaderTpl;
    tpl.stateForUserHeaderTpl = this.stateForUserHeaderTpl;
  }

  isUnseen(row) {
    const stateForUser = parseInt(row.stateForUser);
    if (stateForUser < 0 || stateForUser & TASK_CLIENT_COMMENTED || stateForUser & TASK_EMPLOYEE_COMMENTED) return true;
    return false;
  }

  undoSeenClick(task) {
    this._subscriptions.push(this._tasksBackend.markTaskAsUnseen(task.id).subscribe((resp) => {
      this.taskboardDataService.refreshDatatable.next(true);
    }))
  }

  isEditable(row) {
    return isEditableTask(row.stateId);
  }

  protected _openDialog(cmp, data, opts = DEFAULT_MODAL_OPTS): MatDialogRef<any> {
    return this.dialog.open(cmp, { ...opts, data: data });
  }

  protected updatePartnerClick(row) {
    this._openDialog(UpdateTaskPartnerComponent, { code: row.code, partner: row.partner, id: row.id }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: any) => {
        if (res.success) {
          swal.fire({ title: "Partneris pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
          this.taskboardDataService.refreshDatatable.next(true);
        } else {
          swal.fire({ title: "Partneris pakeisti nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' });
        }
      });
  }

  protected updateTaskTypeClicked(row) {
    this._openDialog(UpdateRequestTypeComponent, { code: row.code, partner: row.partner, taskTypeId: row.taskTypeId, id: row.id }, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Veiklos tipas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        this.taskboardDataService.refreshDatatable.next(true);
      }
    });
  }

  protected transferClicked(row) {
    if (row.taskTypeCode === 'VT_0') {
      swal.fire({
        title: 'Perdavimas negalimas, pasirinkite tipą.',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Atšaukti',
        confirmButtonText: 'Keisti tipą',
        // confirmButtonClass: 'btn-primary',
        customClass: {
          confirmButton: 'btn-primary'
        },
        // buttonsStyling: false
      })
        .then((r) => {
          if (r.value) {
            // this.updateTaskTypeClicked(t);
          }
        });
    } else {
      this._openDialog(TransferFormComponent, { code: row.code, partner: row.partner, id: row.id }, { autoFocus: false }).afterClosed().subscribe(() => {
        this.taskboardDataService.refreshDatatable.next(true);
      });
    }
  }

  gotoChildTasks(row) {
    this._router.navigate(['/taskboard', row.isRequest ? REQUESTS : TASKS, REVIEW, row.id], { queryParams: { childTasks: true } });
  }

  gotoCampaignsEdit(row) {
    this._router.navigate(['/marketing', row.campaignsId]);
  }

  openParticipantsModalClick(campaignsId) {
    this._subscriptions.push(this._modalsService.openDialog(MarketingParticipansListModalComponent, { campaignsId }, { panelClass: 'participans-modal-width-and-height' }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: any) => {
      }));
  }

  downloadParticipantsClick(row) {
    const campaignsId = row.campaignsId;
    this._subscriptions.push(this.marketingCampaignBackend.getCampaignsParticipantList(campaignsId).subscribe(resp => {
      const fieldsList = [];
      const wscols = [{ width: 30 }, { width: 30 }, { width: 30 }, { width: 30 }];
      resp && resp.forEach(el => {
        const excelField = {};
        excelField['El.paštas'] = el.email
        excelField['Įmonės pavadinimas'] = el.companyName;
        excelField['Vardas'] = el.name;
        excelField['Pavardė'] = el.lastname;
        fieldsList.push(excelField);
      })
      const worksheet = XLSX.utils.json_to_sheet(fieldsList);
      worksheet['!cols'] = wscols;
      const workbook1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook1, worksheet, 'Dalyvių sąrašas');
      XLSX.writeFile(workbook1, `${row.name}.xlsx`);
    }));
  }

  openLastComments(row) {
    this._subscriptions.push(this._modalsService.openDialog(CommentsModalComponent, { taskId: row.id }, { panelClass: 'limit-dialog-width-and-height' }).afterClosed().pipe(filter((s) => s))
      .subscribe(res => {
        // const id = row.id;
        // this._stateService.notify(UPDATE_TASKBOARD_LIST, (row) => {
        //   if (row.id == id) {
        //     if (res.dpaId && res.dpaCode && typeof row.setDpa == 'function') {
        //       return row.setDpa(res.dpaId, res.dpaCode);
        //     }
        //   }

        //   return row;
        // });
        // this.taskboardDataService.jobDpaChange.emit(true);
      }));
  }

  _openDpaControls(e: Event, row) {
    e.preventDefault();
    e.stopPropagation();
    this._subscriptions.push(this._modalsService.openDialog(DpaControlsComponent, { jobId: row.id, dpaId: row.dpaId, row: row }, { panelClass: 'limit-dialog-height' }).afterClosed().pipe(filter((s) => s))
      .subscribe(res => {
        const id = row.id;
        this._stateService.notify(UPDATE_TASKBOARD_LIST, (row) => {
          if (row.id == id) {
            if (res.dpaId && res.dpaCode && typeof row.setDpa == 'function') {
              return row.setDpa(res.dpaId, res.dpaCode);
            }
          }

          return row;
        });
        this.taskboardDataService.jobDpaChange.emit(true);
      }));
  }

  _getDeadlineClass(date, state) {
    if (state != TASK_STATE_NEW && state != TASK_STATE_REQUESTER_MODIFIED) {
      return '';
    }
    const d = new Date(date);
    if (today(this._now, d)) {
      return 'today';
    } else if (d.getTime() < this._now.getTime()) {
      return 'late';
    }
    return '';
  }

  getProjectEndDateClass(date) {
    const d = new Date(date);
    // if (today(this._now, d)) {
    // return 'today';
    // } else if (d.getTime() < this._now.getTime()) {
    if (d.getTime() < this._now.getTime()) {
      return 'late';
    }
    return '';
  }

  _getNearestActionClass(row) {
    if (!(row.isActive && row.nearestAction)) {
      return '';
    }
    const d = new Date(row.nearestAction);
    if (today(this._now, d)) {
      return 'today';
    } else if (d.getTime() < this._now.getTime()) {
      return 'late';
    }
    return '';
  }

  _openDpaView(id) {
    this._router.navigate(['/taskboard', DPA, REVIEW, id]);
  }

  _openDpa(id) {
    if (this._openingDpas.indexOf(id) < 0) {
      this._openingDpas.push(id);
      let newWindow = window.open('/assets/loading.html');
      this._filesBackend.getDpaForPrint(id).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        // const fileLink = document.createElement('a');
        // fileLink.href = blobUrl;
        // fileLink.target = '_blank';
        // // fileLink.download = `DPA${id}.pdf`;
        // fileLink.click();
        newWindow && newWindow.location && newWindow.location.assign && newWindow.location.assign(encodeURI(blobUrl));
        this._openingDpas = remove(this._openingDpas, id);
        try {
          this._chg.detectChanges();
        } catch (e) {
        }
      },
        (err) => {
          this._openingDpas = remove(this._openingDpas, id);
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }
      );
    }
  }

  printPdfTmp(id: any) {
    if (this._printingDpas.indexOf(id) < 0) {
      this._printingDpas.push(id);
      this._filesBackend.getDpaForPrint(id).subscribe((response) => {
        var blob = new Blob([response], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
        this._printingDpas = remove(this._printingDpas, id);
        try {
          this._chg.detectChanges();
        } catch (e) {
        }
      },
        (err) => {
          this._printingDpas = remove(this._printingDpas, id);
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }
      );
    }
  }

  _downloadDpa(id: any) {
    if (this._downloadingDpas.indexOf(id) < 0) {
      this._downloadingDpas.push(id);
      this._subscriptions.push(this._filesBackend.downloadDpa(id).subscribe((_) => {
        this._downloadingDpas = remove(this._downloadingDpas, id);
        try {
          this._chg.detectChanges();
        } catch (e) {
        }
      },
        (err) => {
          this._downloadingDpas = remove(this._downloadingDpas, id);
          try {
            this._chg.detectChanges();
          } catch (e) {
          }
        }
      ));
    }

  }

  _isDpaDownloading(id: any) {
    return this._downloadingDpas.indexOf(id) > -1;
  }

  _isDpaPrintinging(id: any) {
    return this._printingDpas.indexOf(id) > -1;
  }

  _isDpaOpening(id: any) {
    return this._openingDpas.indexOf(id) > -1;
  }

  isAptDPA(dpa) {
    return !!(dpa.projectId === 1 && dpa.dpaSysName);
  }

  _sendDpaModal(row) {
    if (this._isSending) {
      return;
    }
    const id = row.id;
    const email = row.delegateEmail;
    const modalRef = this.dialog.open(SendDpaModalComponent, <MatDialogConfig>{ width: '50%' });
    modalRef.componentInstance._dpaNr = DPA_NAME + id;
    modalRef.componentInstance._subject = DPA_NAME + id;

    modalRef.componentInstance._emailBody = getDpaEmailTextV2(id, this.isAptDPA(row));
    modalRef.componentInstance._email = email;
    this._subscriptions.push(modalRef.beforeClosed().subscribe((data) => {
      if (data) {
        const dpaEmailObj = {
          email: data.email,
          subject: data.subject,
          emailBody: data.emailBody,
          dpaId: id
        }
        this._sendDpa(dpaEmailObj);
      }
    }));
  }

  _sendDpa(dpaEmailObj) {
    if (this._isSending) {
      return;
    }
    this._isSending = dpaEmailObj.dpaId;
    this._subscriptions.push(this._dpaBackend.sendDpa(dpaEmailObj).pipe(catchError(err => {
      const s: SweetAlertOptions = {
        text: err.error,
        // icon: 'error',
        showConfirmButton: false,
        timer: 5000
      };
      swal.fire(s);
      this._isSending = false;
      return observableEmpty();
    })).subscribe((res: any) => {
      if (res.success) {
        const s: SweetAlertOptions = {
          text: 'DPA' + dpaEmailObj.dpaId + ' išsiųstas į ' + dpaEmailObj.email,
          // icon: 'success'
        };
        this._isSending = false;
        swal.fire(s);
        this.taskboardDataService.refreshDatatable.next(true);
      } else {
        const s: SweetAlertOptions = {
          text: res.error,
          // icon: 'error',
          showConfirmButton: false,
          timer: 5000
        };
        swal.fire(s);
        this._isSending = false;
      }
    }));
  }

  _getDpaToEmailHref(row) {
    let recepient = row.delegateEmail;
    let options = {
      subject: "DPA" + row.id,
      body: getDpaEmailText(row.id)
    };
    let link = "mailto:";
    link += encodeURIComponent(recepient);
    let params = [];
    for (let o in options) {
      params.push(o.toLowerCase() + "=" + options[o]);
    }
    if (params.length > 0) {
      link += "?" + params.join("&");
    }
    return link;
  }

  _printDpa(id) {
    printDpa(id);
  }

  _openJobForm(row) {
    setTimeout(_ => {
      this._taskboardSidebar.openJobForm(JobFormModel.newTaskJob(this.taskboardDataService, row));
    });
  }

  _reviewJobs(id: any) {
    this._stateService.notify(OPEN_JOBS_TAB, id, true);
  }

  _actionText(row: any) {
    return row && row.nearestActionText && row.nearestActionText.trim().length > 0 ? row.nearestActionText : 'Artimiausių veiksmų nėra';
  }

  _updateTaskState(row: any) {
    if (this._permissionsService.getPermission('change_request_state')) {
      const id = row.id;
      this._subscriptions.push(this._modalsService.openDialog(UpdateRequestStateComponent, { data: row, hasChildren: row.hasChildTasks }).afterClosed().pipe(filter((s) => s))
        .subscribe((res: EntityUpdated<TaskStateChanged>) => {
          const entity = res.entity;
          if (entity) {
            this._stateService.notify(UPDATE_TASKBOARD_LIST, (row) => {
              if (row.id == id) {
                return { ...row, ...entity };
              }
              return row;
            });
          }
        }));
    }
  }

  _updateExecutor(row: any) {
    if (this._permissionsService.getPermission('change_request_state')) {
      const id = row.id;
      this._subscriptions.push(this._modalsService.openDialog(UpdateRequestStateComponent, { data: row, hasChildren: row.hasChildTasks }).afterClosed().pipe(filter((s) => s))
        .subscribe((res: EntityUpdated<TaskStateChanged>) => {
          const entity = res.entity;
          if (entity) {
            this._stateService.notify(UPDATE_TASKBOARD_LIST, (row) => {
              if (row.id == id) {
                return { ...row, ...entity };
              }
              return row;
            });
          }
        }));
    }
  }

  _getTaskUserStates(row) {
    if (!row) {
      return;
    }

    const childsState = parseInt(row.childStatesSum);

    const state = parseInt(row.stateForUser);
    if (state <= 0 && childsState <= 0) {
      return;
    }
    const res = {};
    if (childsState & TASK_STATE_CHANGED) {
      res['childStateChanged'] = 1;
    }
    if (state & TASK_CHILD_ENTERED) {
      res['childEntered'] = 1;
    }
    return res;
  }

  _getChildsState(row) {
    const childsState = parseInt(row.childStatesSum);
    //Jei childsState < 0, vadinasi uzduotis per sena, kad butu nustatinejama vaikiniu uzduociu busenu suma.
    //Jei isNaN - uzduotis neturi vaikiniu uzduociu
    if (childsState < 0 || isNaN(childsState)) {
      return;
    }
    return { state: childsState };
  }

  _startNewTimer(row) {
    this._subscriptions.push(this._modalsService.openDialog(TimerDialogComponent, {}, { minWidth: 600 }).afterClosed().pipe(filter((s) => s))
      .subscribe(res => {
        this._timerService.createTimer(row.id, res);
      }));
  }

  _goToTaskByCode(taskId, code) {
    if (taskId > 1) {
      const origin = window.location.origin;
      window.open(origin + '/#/taskboard/t/v/' + taskId, '_blank');
    }
  }

  _changeIsFavorite(taskId, currentState) {
    if (currentState) {
      this._subscriptions.push(this._tasksBackend.setUnFavorite(taskId).subscribe(() => {
        this.taskboardDataService.refreshDatatable.emit(true);
      }));
    } else {
      this._subscriptions.push(this._tasksBackend.setFavorite(taskId).subscribe(() => {
        this.taskboardDataService.refreshDatatable.emit(true);
      }));
    }
  }

  _changeIsFavoriteCampaigns(campignsId, currentState) {
    if (currentState) {
      this._subscriptions.push(this._tasksBackend.setUnFavoriteCampaign(campignsId).subscribe(() => {
        this.taskboardDataService.refreshDatatable.emit(true);
      }));
    } else {
      this._subscriptions.push(this._tasksBackend.setFavoriteCampaign(campignsId).subscribe(() => {
        this.taskboardDataService.refreshDatatable.emit(true);
      }));
    }
  }

  goToProjects(task) {
    this._router.navigate(['/projects', task.isRequest ? REQUESTS : TASKS, REVIEW, task.id]);
  }

  _taskByCodeTooltip(taskId) {
    return taskId > 1 ? 'Eiti į užduotį' : null;
  }

  _getNearestActions(task) {
    if (task.nearestAction) {
      const modalRef = this.dialog.open(ActionModalComponent, { panelClass: 'custom-modal' });
      modalRef.componentInstance._task = task;
      modalRef.componentInstance._taskId = task.id;
      this._subscriptions.push(modalRef.beforeClosed().subscribe((data) => {
        if (data) {
          this.taskboardDataService.refreshDatatable.emit(true);
        }
      }));
    } else {
      const modalRef = this.dialog.open(NearestActionFormComponent, { ...{}, data: { task: task } });
      this._subscriptions.push(modalRef.beforeClosed().subscribe((data) => {
        this.taskboardDataService.refreshDatatable.emit(true);
      }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

}
