
import { from as observableFrom, Subject, BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';

import { tap, filter, combineLatest, toArray, distinct, map, switchMap } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PartnersBackend } from "../../../../backend/partners.backend";

@Component({
  selector: 'licences-list',
  templateUrl: './licencesList.component.html',
  styleUrls: ['./licencesList.component.scss'],
})
export class LicencesListComponent implements OnInit, OnInit, OnDestroy {
  protected isLoading = true;
  @Input() partnerId;
  private limit = 15;
  private rows: Subject<any> = new ReplaySubject(1);
  protected products: Subject<any> = new ReplaySubject(1);
  protected visibleRows: Observable<any>;
  private selectedProduct: Subject<string> = new ReplaySubject(1);
  protected mapProduct = (p) => p;
  protected columns = [
    { name: 'Produktas', prop: 'product', headerClass: 'table-header', cellClass: 'table-cell' },
    { name: 'Modulis', prop: 'module', headerClass: 'table-header', cellClass: 'table-cell' },
    { name: 'Sutartis', prop: 'contract', headerClass: 'table-header', cellClass: 'table-cell' },
  ];
  private subscriptions: Array<Subscription> = [];
  private _searchTerms = new BehaviorSubject<string>('');
  private term: string;
  constructor(private partnersBackend: PartnersBackend,
    private _chg: ChangeDetectorRef) {

  }
  ngOnInit(): void {
    this.partnersBackend.getPartnerLicences(this.partnerId).subscribe((r: Array<any>) => {
      this.rows.next(r);
      this.isLoading = false;
      try {
        this._chg.detectChanges();
      } catch (err) { }
    });
    this.subscriptions.push(this.rows.pipe(switchMap((rows) =>
      observableFrom(rows).pipe(
        map((r) => (<any>r).product),
        distinct(),
        toArray()
      ))).subscribe((res) => {
        this.products.next(res);
      }));
    this.subscriptions.push(this.products.subscribe((p) => {
      if (p.length > 0) {
        this.selectedProduct.next(p[0]);
      }
    }));
    this.visibleRows = this.rows.pipe(combineLatest(this.selectedProduct, (rows, p) => ({ rows: rows, product: p })), filter((o) => o.product != null), map((o) => o.rows.filter((row) => row.product == o.product)), combineLatest(this._searchTerms.pipe(map(t => t.toLowerCase())), (rows, term) => term.length < 1 ? rows : rows.filter(r => r.contract.toLowerCase().indexOf(term) > -1 || r.product.toLowerCase().indexOf(term) > -1 || r.module.toLowerCase().indexOf(term) > -1)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  _productSelected(product) {
    this.selectedProduct.next(product);
  }

  _searchLicence(text: string) {
    this._searchTerms.next(text);
  }

  clearSearch() {
    this.term = '';
    this._searchTerms.next(this.term);
  }
}
