
import { map, tap, pluck, withLatestFrom, filter } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableModel } from "../../template/shared/proto/dataTable/serverTable/TableModel";
import { TOGGLE_LEFT_MENU } from "../../state/state";
import { StateService } from "../../services/state/stateService";
import { Subscription, Subject, of } from "rxjs";
import { StorageService } from "../../template/shared/proto/storage/storage.service";
import { QueryProviderDirective } from "../../template/shared/proto/common/query/queryProvider.directive";
import { ColumnResized, ServerTableComponent } from "../../template/shared/proto/dataTable/serverTable/serverTable.component";
import { StorageOptions } from "../../template/shared/proto/storage/StorageOptions";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModalComponent } from '../../template/shared/components/modals/delete-modal/delete-modal.component';
import { UserBackend } from "../../backend/user.backend";
import { EntityList } from '../../core/api/response/entity-list';
import swal from 'sweetalert2';
import { faBars, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { PartnersBackend } from '../../backend/partners.backend';
import { QueryPartDirective } from '../../template/shared/proto/common/query/queryPart.directive';

const mapToRes = (res: Array<any>) => {
  const a = ({ count: res.length > 0 && res[0].count ? res[0].count : res.length, data: res });
  return a;
};

const applyCurrentPage = (q: any) => {
  if (q.recordsPerPage > 0) {
    return {
      ...q,
      startFrom: (q.page) * q.recordsPerPage
    };
  } else {
    q.recordsPerPage = 99999999;
    return {
      ...q,
      startFrom: 0
    };
  }
};
const defaultParams = (query: any, p: any = {}) => {
  return applyCurrentPage(({ ...query, ...p }))
};
const noAddition = (query: any) => { return removeEmpty(defaultParams(query)) };
const removeEmpty = (query: any) => {
  Object.keys(query).forEach((key) => (query[key] == null || query[key] == '' || query[key] == undefined) && delete query[key]);
  return query;
};
const withAddition = (paramsToAdd: any) => (query: any) => { return defaultParams(query, paramsToAdd) };
const withCondition = (condition: (params: any) => any) => (query: any) => defaultParams(query, condition(query));


@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss'],
})
export class AdminListComponent implements OnInit, AfterViewInit, OnDestroy {

  loading: any;

  @ViewChild('editTpl', { read: TemplateRef, static: true }) _editTpl: TemplateRef<any>;
  @ViewChild('isActiveTpl', { read: TemplateRef, static: true }) _isActiveTpl: TemplateRef<any>;
  @ViewChild('confirmTpl', { read: TemplateRef, static: true }) _confirmTpl: TemplateRef<any>;
  @ViewChild('searchField') _searchField: ElementRef;
  @ViewChild(QueryProviderDirective) _queryProvider: QueryProviderDirective;
  @ViewChild(ServerTableComponent) _serverTable: ServerTableComponent;

  protected _sortingMapper = (v) => {
    // let col = this._tm.columns.find((c) => c.prop == v.prop);
    //return ({orderBy: col.prop, orderDir: v.order});
    let col = v.prop ? this._tm.columns.find((c) => c.prop == v.prop) : this._tm.columns.find((c) => c.sortable !== false);
    if (this._tm.name !== 'partners') {
      return col ? ({ orderId: col.orderId, orderUpId: v.order == "asc" ? 0 : 1 }) : v;
    } else {
      return col ? ({ orderBy: col.prop, orderUp: v.order }) : v;
    }
  };
  protected _selectedRows: Array<any> = [];
  private _lastId: any;
  private _subscriptions: Array<Subscription> = [];
  private __tm;
  private _colSettings: Array<any> = [];
  protected ctm;
  protected _isVisible: boolean = false;
  protected _tm: TableModel;
  protected _currentPage: number = 3;
  private _rowClicks: Subject<any> = new Subject();
  private _pageSavingOptions: StorageOptions = { popState: true, refresh: true };
  protected _showSearchClear = false;
  private _flags;
  private _showConfirmationList = false;


  private _tModels: Array<TableModel>;

  private _applySelected = (res) => {
    if (!res || !res.data || res.data.length < 1) {
      return;
    }
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].id == this._lastId) {
        this._selectedRows.push(res.data[i]);
        return;
      }
    }
  };

  private _tableConstructors = {
    users: ({
      new: ["../users/new"],
      hasButton: true,
      id: 'users',
      title: 'Vartotojai',
    }),
    employees: ({
      new: ["../employees/new"],
      hasButton: true,
      id: 'employees',
      title: 'Darbuotojai'
    }),
    partners: ({
      id: 'partners',
      title: 'Partneriai'
    }),
    delegates: ({
      new: ["../delegates/new"],
      hasButton: true,
      id: 'delegates',
      title: 'Atstovai'
    }),
    confirmation: ({
      id: 'confirmation',
      title: 'Tvirtinimas'
    })
  };

  faBars = faBars;
  faPlusSquare = faPlusSquare;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _stateService: StateService,
    private _storageService: StorageService,
    private _chg: ChangeDetectorRef,
    private _userBackend: UserBackend,
    private _partnersBackend: PartnersBackend,
    public dialog: MatDialog) {

  }

  ngOnInit() {
    this._tModels = [
      {
        name: 'users',
        columns:
          [
            { name: 'Prisijungimo vardas', prop: 'userName', width: 190, orderId: 7, sorted: { order: 'desc' } },
            { name: 'Vardas', prop: 'name', width: 190, orderId: 8 },
            { name: 'Pavardė', prop: 'lastName', width: 190, orderId: 9 },
            { name: 'Tipas', prop: 'typename', width: 120, orderId: 10 },
            { name: 'Partneris', prop: 'companyName', width: 150, orderId: 93 },
            { name: 'Aktyvus', prop: 'active', cellTemplate: this._isActiveTpl, orderId: 92 }
          ],
        getData: (q) => (this._userBackend.getUsers(noAddition(q)).pipe(
          map((resp: any) => {
            this.reloadPageOnCondition(noAddition(q), resp.entities);
            return {
              data: resp.entities,
              count: resp.recordsCount || 0
            }
          }),
          tap(
            this._applySelected
          ))
        )
      },
      {
        name: 'employees',
        columns:
          [
            { name: 'Vardas', prop: '', width: 190, orderId: 1, sorted: { order: 'desc' } },
            { name: 'Pavardė', prop: '', width: 190, orderId: 2 },
            { name: 'Pareigos', prop: '', width: 85, orderId: 65 },
            { name: 'Mobilus tel.', prop: '', width: 120, orderId: 66 },
            { name: 'Vidinis tel.', prop: '', width: 120, orderId: 63 },
            { name: 'El. paštas', prop: '', width: 120, orderId: 64 },
            { name: '', prop: '', width: 120 },
            { name: '', prop: '', width: 120 }
          ],
        getData: (q) => (q)
      },
      {
        name: 'partners',
        columns:
          [
            { name: 'Pavadinimas', prop: 'name', width: 190, orderId: 1, sorted: { order: 'asc' } },
            { name: 'Įmonės kodas', prop: 'code', width: 190, orderId: 2 },
            { name: 'Miestas', prop: 'city', width: 85, orderId: 3 },
            { name: 'Telefonas', prop: 'telephone', width: 120, orderId: 4 },
            { name: 'Mobilus telefonas', prop: 'mobile', width: 120, orderId: 5 },
            { name: 'El paštas', prop: 'email', width: 120, orderId: 6 },
          ],
        getData: (q) => (this._partnersBackend.getPartnersList(noAddition(q)).pipe(
          map((resp: any) => {
            this.reloadPageOnCondition(noAddition(q), resp.entities || []);
            return {
              data: (resp?.entities || []),
              count: resp?.recordsCount || 0
            }
          }),
          tap(
            this._applySelected
          )))
      },
      {
        name: 'delegates',
        onRowClick: (r: any) => {
        },
        columns:
          [
            { name: 'Vardas', prop: 'name', width: 190, orderId: 1, sorted: { order: 'desc' } },
            { name: 'Pavardė', prop: 'lastName', width: 190, orderId: 1 },
            { name: 'Pareigos', prop: 'position', width: 120, orderId: 62, },
            { name: 'Telefonas', prop: 'phone', width: 140, orderId: 60 },
            { name: 'Mobilus telefonas', prop: 'mobile', width: 140, orderId: 61 },
            { name: 'El. paštas', prop: 'email', width: 140, orderId: 64 },
            { name: 'Vartotoju sk.', prop: 'usersCount', width: 120 },
          ],
        getData: (q) => (this._userBackend.getDelegatesList(noAddition(q)).pipe(
          map((resp: any) => {
            this.reloadPageOnCondition(noAddition(q), resp?.entities || []);
            return {
              data: (resp?.entities || []).map(x => { return { ...x, id: x.delegateId, count: resp?.recordsCount } }),
              count: resp?.recordsCount || 0
            }
          }),
          tap(
            this._applySelected
          )))
      },
      {
        name: 'confirmation',
        columns: [
          { name: 'Prisijungimo vardas', prop: 'userName', sortable: false, width: 190, sorted: { order: 'desc' } },
          { name: 'Vardas', prop: 'name', sortable: false, width: 190 },
          { name: 'Pavardė', prop: 'lastName', sortable: false, width: 190 },
          { name: 'Įmonė', prop: 'client', sortable: false, width: 190 },
          { name: 'Įmonės kodas', prop: 'clientCode', sortable: false, width: 190 },
          { name: 'Sukurė', prop: 'creator', sortable: false, width: 190 },
          { name: 'Patvirtintas', prop: 'confirmed', cellTemplate: this._confirmTpl, sortable: false, width: 190 },
        ],
        getData: (q) => (this._userBackend.getUnverifiedUsers(noAddition(q)).pipe(
          map((resp: EntityList<any>) => {
            this.reloadPageOnCondition(noAddition(q), resp);
            return {
              data: resp,
              count: resp ? resp.length : 0
            }
          }),
          tap(
            this._applySelected
          )))
      }
    ];
  }

  reloadPageOnCondition(params, entities) {
    if (entities.length === 0 && params.page !== 0 && params.page !== undefined && params.page !== null) {
      this._pageChanged(0);
      location.reload();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private _toggleLeftMenu() {
    this._stateService.notify(TOGGLE_LEFT_MENU);
  }


  ngAfterViewInit(): void {


    this._subscriptions.push(
      this._rowClicks.pipe(
        withLatestFrom(
          this._route.params.pipe(pluck('type')).pipe(
            withLatestFrom(
              this._route.queryParams.pipe(pluck('id')),
              (p, id) => ({
                params: p,
                id
              })
            )
          ),
          (row, t) => {
            return ({
              row: row,
              t: t
            })
          }
        )
        , filter((rt) => rt.t != null && (<any>(rt.t)).params && (rt.row.id || rt.row.usersId || rt.row.partnerId))
      )
        .subscribe((rt) => {
          if (this.ctm.id === 'users') {
            if (rt.row.usersId) {
              this._router.navigate(['../', (<any>(rt.t)).params, rt.row.usersId], { relativeTo: this._route });
            }
          } else if (this.ctm.id === 'partners') {
            if (rt.row.partnerId) {
              this._router.navigate(['../', (<any>(rt.t)).params, rt.row.partnerId], { relativeTo: this._route });
            }
          } else {
            if (rt.row.id) {
              this._router.navigate(['../', (<any>(rt.t)).params, rt.row.id], { relativeTo: this._route });
            }
          }
        }));

    this._subscriptions.push(this._route.params.pipe(pluck('type')).subscribe((d: any) => {

      d == 'users' ? this._showConfirmationList = true : this._showConfirmationList = false;

      this._isVisible = false;
      this._selectedRows = [];
      //Parenkamas atitinkamas konstruktorius
      this.ctm = this._tableConstructors[d];
      //Atstatomas pasirinktas eilutes ID, tam, kad matytusi ant kurios eilutes buvo paspausta
      this._lastId = this._storageService.get(this.ctm.id, null, { popState: true, static: true });
      //Pakraunami pasirinkti stulpeliai ir ju plociai
      let colSettings = this._storageService.get(this.ctm.id + 'colSettings') || [];
      let sortSettings = this._storageService.get(this.ctm.id + 'sort');
      //Gaunamas reikiamas TableModel
      let __tm = this._tModels.find((tm) => tm.name == d);

      //Aplungiami TableModel nustatymai, su issaugotais atmintyje
      for (let i = 0; i < __tm.columns.length; i++) {
        let cs = colSettings.find((s) => s.name == __tm.columns[i].name);
        if (cs) {
          __tm.columns[i].visible = cs.visible != undefined ? cs.visible : true;
          __tm.columns[i].width = cs.width || __tm.columns[i].width || 200;
          __tm.columns[i].index = cs.index || i;
        } else {
          __tm.columns[i].width = __tm.columns[i].width || 200;
          __tm.columns[i].index = i;
        }
      }
      if (sortSettings && sortSettings.prop) {
        let _c = __tm.columns.find((c) => c.prop == sortSettings.prop);
        if (_c) {
          let _dc = __tm.columns.find((c) => c.sorted && true);
          delete _dc['sorted'];
          _c.sorted = { order: sortSettings.order };
        }
      }
      this._colSettings = colSettings;
      this._tm = __tm;
      //Perkraunama lentele
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this._isVisible = true;
      this._currentPage = this._storageService.get('currentPage', 0, this._pageSavingOptions);
      try {
        this._chg.detectChanges();
      } catch (err) { }
      if (this.ctm.id !== 'confirmation') {
        this._searchField.nativeElement.value = this._storageService.get(this.ctm.id + '.search', undefined, { popState: true, refresh: true }) || '';
        this._searchField.nativeElement.value != '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};
      }
      let sortingQp = this._serverTable.getSortingQueryPart();
      let pagingQp = this._serverTable.getPagingQueryPart();

      this._queryProvider.registerQueryPart(sortingQp);
      this._queryProvider.registerQueryPart(pagingQp);

      this._queryProvider.active = true;
      //Trigerinama paieska
      this._queryProvider.emit('sort');
      this._queryProvider.emit('page');
      this._queryProvider.emit('search');

    }));

  }

  _addNewClicked() {
    this._isVisible = false;
    this._router.navigate(this.ctm.new, { relativeTo: this._route });
  }

  private _pageChanged(page) {
    this._storageService.store('currentPage', page, this._pageSavingOptions);
  }

  private _search(val) {
    val = val && val.trim() == '' ? '' : val;
    this._storageService.store(this.ctm.id + '.search', val, { popState: true, refresh: true });
    document.getElementById('content_header_search').blur();
    this._queryProvider.emit('search');
  }

  _onSort(e) {
    this._storageService.store(this.ctm.id + 'sort', { prop: e.prop, order: e.order });
  }

  private _columnResized(e: ColumnResized) {
    let cs = this._colSettings.find((cs) => cs.name == e.name);
    if (!cs) {
      this._colSettings.push({ name: e.name, width: e.newWidth });
    } else {
      cs.width = e.newWidth;
    }
    this._storageService.store(this.ctm.id + 'colSettings', this._colSettings);
  }

  _delete(userInfo) {
    this.dialog.open(DeleteModalComponent, { data: userInfo });
  }

  private _clearSearch() {
    this._searchField.nativeElement.value = '';
    this._searchField.nativeElement.value == '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};
    this._storageService.store(this.ctm.id + '.search', undefined, {
      popState: true,
      refresh: true
    });
    this._queryProvider.emit('search');
  }

  private _detectSearchVal(val) {
    val.length > 0 ? this._showSearchClear = true : this._showSearchClear = false;
  }

  private _rowClicked(row: any) {
    if (this.ctm.id === 'partners') {
      this._storageService.store(this.ctm.id, row.partnerId, { popState: true, static: true });
    } else {
      this._storageService.store(this.ctm.id, row.id, { popState: true, static: true });
    }
    this._rowClicks.next(row);
  }

  protected _confirmUser(user) {
    if (!user.confirming) {
      user.confirming = true;
      this._userBackend.confirmUser(user.usersId).subscribe(res => {
        if (res) {
          if (res.success) {
            user.confirmed = true;
          }
          user.confirming = false;
          try {
            this._chg.detectChanges();
          } catch (e) {

          }
        }
      });
    }
  }

  protected _confirmUserModal(user) {
    swal.fire({
      title: 'Ar tikrai norite patvirtinti vartotoją?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          this._confirmUser(user);
        }
      });
  }

  protected _deactivateUserModal(user) {
    swal.fire({
      title: 'Ar tikrai norite deaktyvuoti vartotoją?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          this._deactivateUser(user);
        }
      });
  }

  protected _deactivateUser(user) {
    if (!user.deactivating) {
      user.deactivating = true;
      this._userBackend.deactivateUser(user.usersId).subscribe(res => {
        if (res.success) {
          user.isActive = false;
          user.deactivating = false;
          try {
            this._chg.detectChanges();
          } catch (e) {

          }
        }
      });
    }
  }

  protected _activateUserModal(user) {
    swal.fire({
      title: 'Ar tikrai norite aktyvuoti vartotoją?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          this._activateUser(user);
        }
      });
  }

  protected _activateUser(user) {
    if (!user.activating) {
      user.activating = true;
      this._userBackend.activateUser(user.usersId).subscribe(res => {
        if (res.success) {
          user.isActive = true;
          user.activating = false;
          try {
            this._chg.detectChanges();
          } catch (e) {

          }
        }
      });
    }
  }

  protected _cancelUserConfirmation(user) {
    if (!user.canceling) {
      user.canceling = true;
      this._userBackend.cancelUserConfirmation(user.usersId).subscribe(res => {
        if (res) {
          if (res.success) {
            this._queryProvider.emit('search');
          }
          user.canceling = false;
          try {
            this._chg.detectChanges();
          } catch (e) {

          }
        }
      });
    }
  }

  protected _cancelUserConfirmationModal(user) {
    swal.fire({
      title: 'Ar tikrai norite atšaukti vartotojo registraciją?',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Ne',
      confirmButtonText: 'Taip',
      // confirmButtonClass: 'btn-primary',
      customClass: {
        confirmButton: 'btn-primary'
      },
      // buttonsStyling: false
    })
      .then((r) => {
        if (r.value) {
          this._cancelUserConfirmation(user);
        }
      });
  }
}
