
import { map, startWith, combineLatest, filter } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, BehaviorSubject, Observable, ReplaySubject, Subscription } from "rxjs";
import { PartnersBackend } from "../../../../backend/partners.backend";
import { thisMonth } from "../../../../functions/utils/date/date.utils"
import { SHORT_DATE } from '../../../../core/time';

@Component({
  selector: 'sales-list',
  templateUrl: './salesList.component.html',
  styleUrls: ['./salesList.component.scss'],
})
export class SalesListComponent implements OnInit, OnInit, OnDestroy, AfterViewInit {
  @Input() partnerId;
  private rows: Subject<any> = new BehaviorSubject(null);
  visibleRows: Observable<Array<any>>;
  private thisMonth: Date;
  private sum: Observable<number>;
  private dateTo: Date;
  shortDate = SHORT_DATE;

  private filterFunctions: Subject<(r: any) => boolean> = new ReplaySubject(1);

  private columns = [
    { name: 'Data', prop: 'date', headerClass: 'date-row row-param', cellClass: 'date-row row-param' }, //ok
    { name: 'Dok. nr', prop: 'documentNr', headerClass: 'doc-row row-param', cellClass: 'doc-row row-param' },
    { name: 'Kodas', prop: 'itemCode', headerClass: 'type-row row-param', cellClass: 'type-row row-param' },
    { name: 'Vertybė', prop: 'itemName', headerClass: 'item-row row-param', cellClass: 'item-row row-param' },
    { name: 'Kiekis', prop: 'quantity', headerClass: 'amount-row row-param', cellClass: 'amount-row row-param' },
    { name: 'Suma Be PVM', prop: 'sumWithoutVAT', headerClass: 'sumNoVAT-row row-param', cellClass: 'sumNoVAT-row row-param' },
    { name: 'Vykdytojas', prop: 'sellerName', headerClass: 'name-row row-param', cellClass: 'name-row row-param' },
    { name: 'Padalinys', prop: 'company', headerClass: 'company-row row-param', cellClass: 'company-row row-param' },
  ];
  private subscriptions: Array<Subscription> = [];

  constructor(private partnersBackend: PartnersBackend,
    private chg: ChangeDetectorRef) {
    this.thisMonth = thisMonth();
  }

  ngOnInit(): void {
    this.partnersBackend.getPartnerSales(this.partnerId).subscribe((r) => this.rows.next(r));
    this.visibleRows = this.rows.pipe(filter((r) => r != null), combineLatest(this.filterFunctions.pipe(startWith((_: any) => true)), (rows, filterFn) => rows.filter((r) => filterFn(r))));
    this.sum = this.visibleRows.pipe(map((res: Array<any>) => res.reduce((acc, row) => acc + row.sumWithoutVAT, 0).toFixed(2)));
    // this.sum = this.visibleRows.pipe(map((res: Array<any>) => res.reduce(
    //   (acc, row) => {
    //     console.log('this.1', acc, row.sumWithoutVat);
    //     return acc + (row && row.sumWithoutVat ? parseFloat(row.sumWithoutVat.replace(',', '.')) : ''), 0
    //   }
    // ).toFixed(2)));
  }

  ngAfterViewInit(): void {
    try {
      this.chg.detectChanges();
    } catch (err) { }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  _intervalChanged(interval) {
    this.dateTo = interval.to == '' ? new Date() : new Date(interval.to);
    this.filterFunctions.next((row) => {
      let date = new Date(row.date);
      return date >= new Date(interval.from) && date <= this.dateTo
    });
  }
}
