import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class ProjectsBackend {
  constructor(private _api: ApiService) {
  }

  savedParams: any;

  getProjectServices(taskTypeId): Observable<any[]> {
    return <any>this._api.getAuth(`projects/services/${taskTypeId}`).pipe(map((res: any) => res.body.entities));
  }

  getProjectTypes(): Observable<any[]> {
    return <any>this._api.getAuth(`projects/types/0`).pipe(map((res: any) => res.body.entities));
  }

  createProject(createObj) {
    return <any>this._api.postAuth(`projects`, createObj).pipe(map((res: any) => res.body.entity));
  }

  updateProject(projectId, createObj): Observable<any[]> {
    return <any>this._api.postAuth(`projects/${projectId}`, createObj).pipe(map((res: any) => res.body.entity));
  }

  getProjectStages(taskTypeId): Observable<any[]> {
    return <any>this._api.getAuth(`projects/stages/${taskTypeId}`).pipe(map((res: any) => res.body.entities));
  }

  updateProjectStage(projectId, stageId) {
    return <any>this._api.postAuth(`projects/${projectId}/stage/${stageId}`, {}).pipe(map((res: any) => res.body.entity));
    // return of({ success: true });
  }

  getProjectsServicesTime(taskId) {
    return <any>this._api.getAuth(`projects/task/${taskId}/services`).pipe(map((res: any) => res.body.entity));
  }

  updateWarrantyDate(projectId, warrantyDate) {
    return this._api.postAuth(`projects/${projectId}/warranty`, { warrantyDate }).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }));
  }

  public getProjectsList(params: Object): any {
    const parms: any = params;
    const pars: any = {};
    pars.pageLength = parms.recordsPerPage;
    pars.startFrom = parms.page * parms.recordsPerPage;
    pars.filter = parms.search ? parms.search : '';
    pars.orderBy = parms.orderBy;
    pars.orderDir = parms.orderDir;
    pars.dateFrom = parms.from;
    pars.dateTo = parms.to;
    pars.type = parms.type ? parms.type : 0;
    if (parms.partnerId) pars.partneriaiId = parms.partnerId;
    let mask = 0;
    for (let i = 1; i < 40; i++) {
      if (params[i] !== undefined) {
        if (params[i]) mask |= Math.pow(2, i - 1);
      } else {
        break;
      }
    }
    pars.stageBitmask = mask;
    if (pars.type == 0) {
      pars.groupId = parms.groupId ? parms.groupId : 0;
    }
    pars.allowAdditionToProject = parms.allowAdditionToProject ? 1 : 0;
    this.savedParams = pars;
    return <any>this._api.getAuth('projects/list', pars).pipe(map(x => x.body));
  }

  getProjectsForExport(): Observable<any[]> {
    return <any>this._api.getAuth('projects/list', { ...this.savedParams, startFrom: 0, pageLength: 999999 }).pipe(map((res: any) => res.body));
  }

  // addTaskToProject(taskId, projectId) {
  //   return <any>this._api.postAuth(`tasks/${taskId}/addToProject/${projectId}`, {}).pipe(map((res: any) => res.body.entity));
  // }

  addTaskToProjectChild(taskId, parentTaskId) {
    return <any>this._api.postAuth(`tasks/${taskId}/addToProject/${parentTaskId}`, {}).pipe(map((res: any) => res.body.entity));
  }
}
