
import { toArray, filter, mergeMap, map, tap } from 'rxjs/operators';
import { TasksBackend } from '../../../../backend/tasks.backend';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TaskReview } from "../../../models/task-review";
import {
  TASK_STATE_CANCELED,
  TASK_STATE_COMPLETED,
  TASK_STATE_EXECUTING,
  TASK_STATE_FINISHED,
  TASK_STATE_POSTPONED
} from "../../../../core/constants";
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'update-request-state',
  templateUrl: 'updateRequestState.component.html',
  styleUrls: ['updateRequestState.component.scss']
})
export class UpdateRequestStateComponent implements OnInit {

  @ViewChild('submitBtn') submitBtn: ElementRef;
  @ViewChild('transferForm') _form;
  statesSource: Function;
  private initialSelector: Function;
  _updateStateForm: UntypedFormGroup;
  _updating: boolean;
  private _updated: boolean;
  notRequiredNoteStateIdsList = [];
  hasChildren: boolean;
  data: TaskReview;

  _isInserted: any;

  calendarCustomHeaderComponent = CalendarCustomHeaderComponent;
  statesList: any;
  dateStart: any = new Date();

  _validateForm(f: UntypedFormGroup) {
    const v = parseInt(f.controls['stateId'].value);
    let errors = null;
    if (v && f.controls['text'].value.length < 1 && (this.notRequiredNoteStateIdsList.indexOf(v) == -1)) {
      errors = ['Description must be provided'];
    }
    f.controls['text'].setErrors(errors);
    return errors;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private dataPar: any,
    private tasksBackend: TasksBackend,
    public dialogRef: MatDialogRef<UpdateRequestStateComponent>,
    private permissionsService: NgxPermissionsService,
    private _formBuilder: UntypedFormBuilder) {
    const data = dataPar.data;
    this.data = data;
    this.statesSource = () => this.tasksBackend.getTaskStatesForChanging(data.id).pipe(
      mergeMap((s: any) => s),
      filter((s) => (<any>s).id != data.stateId),  //Isfiltruojam, kad nebutu galima pasirinkti dabartines busenos
      toArray(),
      tap((st: any) => {
        this.statesList = st;
        st.forEach((stt) => {
          if (stt.code == 'Atlikta' || stt.code == 'Baigta') {
            this.notRequiredNoteStateIdsList.push(stt.stateId);
          }
        })
      }));
    this.initialSelector = (p) => {
      return data.stateId == p.id;
    };
  }

  ngOnInit(): void {
    this.hasChildren = this.dataPar.hasChildren;
    this._buildForm();
  }

  dateChanged(el) {
    el.close && el.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  private _updateStateBackend(params) {
    this._updating = true;
    params.RecountDeadline = 0;
    const date = new Date(this.dateStart);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    this.permissionsService.hasPermission('change_dateStart') && this.showStartDateField() ? params.dateStart = date : null;
    this.tasksBackend.changeTaskState(params)
      .subscribe((res) => {
        this._updating = false;
        this._updated = true;
        this.dialogRef.close(res);
        if (res.success) {
          swal.fire({
            title: "Būsena pakeista sėkmingai",
            timer: 2000,
            showConfirmButton: false,
            icon: 'success'
          })
            // .catch(swal.noop)
            .then(_ => this.dialogRef.close(res))
        } else {
          const msg = `Būsenos pakeisti nepavyko. ${res.error && res.error.indexOf('2000001') > -1 ? 'Yra nebaigtų vaikinių užduočių.' : ''}`;
          swal.fire({
            title: msg,
            timer: 3000,
            showConfirmButton: false,
            icon: 'error'
          })
            // .catch(swal.noop)
            .then(_ => this.dialogRef.close(res));
        }
      }, (err) => {
        this._updating = false;
        const msg = `Būsenos pakeisti nepavyko. ${err.error && err.error.indexOf('2000001') > -1 ? 'Yra nebaigtų vaikinių užduočių.' : ''}`;
        swal.fire({
          title: msg,
          timer: 3000,
          showConfirmButton: false,
          icon: 'error'
        })
          // .catch(swal.noop)
          .then(_ => this.dialogRef.close(err));
      })
  }

  private _buildForm() {
    this._updateStateForm = this._formBuilder.group({
      isInternal: [0],
      taskId: [this.data.id],
      text: [''],
      actionType: [0],
      stateId: [null, [Validators.required, (control: AbstractControl) => this.data && this.data.stateId == control.value ? ['State must be changed'] : null]]
    }, { validator: this._validateForm.bind(this) });
  }

  showStartDateField() {
    return this.statesList && this._updateStateForm.controls.stateId.value === this.statesList.find(el => el.code == 'Atidėta')?.stateId;
  }

  _updateState(form: UntypedFormGroup) {
    if (form.invalid || this._updating) {
      return;
    }

    const params = form.value;
    const childsTree = (<any>this.data).childsTree;
    const parentTaskId = (<any>this.data).parentTaskId;
    // console.log(params, childsTree, parentTaskId, this.hasChildren);
    if ((childsTree || parentTaskId == 1) && (params.stateId == TASK_STATE_COMPLETED || params.stateId == TASK_STATE_FINISHED)) {

      if (((childsTree && childsTree.id) == params.taskId || parentTaskId == 1) && this.hasChildren) {

        if (childsTree && childsTree.children.find(child => child.stateId !== TASK_STATE_COMPLETED && child.stateId !== TASK_STATE_FINISHED)) {
          swal.fire({
            title: 'Ar norite kartu uždaryti ir vaikines užduotis?',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Ne',
            confirmButtonText: 'Taip',
            // confirmButtonClass: 'btn-primary',
            customClass: {
              confirmButton: 'btn-primary'
            },
            // buttonsStyling: false
          }).then((r) => {
            if (r.value) {
              params.closeChildTasks = true;
              this._updateStateBackend(params);
            } else {
              this._updateStateBackend(params);
            }
          });
        } else {
          this._updateStateBackend(params);
        }
      } else {
        this._updateStateBackend(params);
      }
    } else {
      this._updateStateBackend(params);
    }

  }
}
