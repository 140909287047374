
import { map, pluck, withLatestFrom, filter, tap, delay } from 'rxjs/operators';
import { JOB_ENTERED, UPDATE_TASKBOARD_LIST } from './../../state/state';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { DPA, DPAAPT, GROUPDPA, JOBS, NEW, PARTNER, REVIEW, TYPE } from '../taskboard.help';
import { TableModel } from '../../template/shared/proto/dataTable/serverTable/TableModel';
import { PRIORITY_ABBR, PRIORITY_COLORS, PRIORITY_TOOLTIPS } from '../taskboard';
import { StateService } from '../../services/state/stateService';
import { TOGGLE_LEFT_MENU } from '../../state/state';
import { TaskboardSidebarService } from '../services/taskboardSidebar.service';
import { SelectedPartnerService } from '../../services/partner/selectedPartner.service';
import { StorableDirective } from '../../template/shared/proto/storage/storable.directive';
import { StorageOptions } from '../../template/shared/proto/storage/StorageOptions';
import { StorageService } from '../../template/shared/proto/storage/storage.service';
import {
  ColumnResized,
  ServerTableComponent
} from '../../template/shared/proto/dataTable/serverTable/serverTable.component';
import { QueryProviderDirective } from '../../template/shared/proto/common/query/queryProvider.directive';
import { NgxPermissionsService } from 'ngx-permissions';
import { TasksBackend } from '../../backend/tasks.backend';
import { TplService } from '../services/tpl.service';
import { TaskboardDataService } from '../services/taskboard-data.service';
import { TASK_CHILD_ENTERED, TASK_CLIENT_COMMENTED, TASK_EMPLOYEE_COMMENTED, TASK_JOB_ENTERED, TASK_STATE_CHANGED } from '../common/constants';
import { JobFormModel } from '../models/job-form';
import { JobsBackend } from '../../backend/jobs/jobs.backend';
import { EntityList } from '../../core/api/response/entity-list';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { faBars, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { MY_TASKS_TYPE, REQUESTS_FOR_ME_TYPE, REQUESTS_FOR_MY_GROUP_TYPE, REQUESTS_TRANSFERS_TYPE, TASKS_FOR_ME_TYPE, TASKS_FOR_MY_GROUP_TYPE, TASKS_TRANSFERS_TYPE } from '../../core/constants';
import { DpaBackend } from '../../backend/dpa.backend';
import { isActiveState } from '../common/functions';
import { DateInterval } from '../../components/filters/date-interval-alt/dateIntervalAlt.component';

const cloneArr = (arr: object[]) => arr.map(o => ({ ...o }));

// unused function
const mapToRes = (res: Array<any>) => ({
  count: res.length > 0 && res[0].recordsCount ? res[0].recordsCount : res.length,
  data: res
});
const applyCurrentPage = (q: any) => {
  if (q.recordsPerPage > 0) {
    return {
      ...q,
      startFrom: (q.page) * q.recordsPerPage
    };
  } else {
    q.recordsPerPage = 99999999;
    return {
      ...q,
      startFrom: 0
    };
  }
};
const defaultParams = (query: any, p: any = {}) => applyCurrentPage(({ ...query, ...p }));
const noAddition = (query: any) => defaultParams(query);
const withAddition = (paramsToAdd: any) => (query: any) => defaultParams(query, paramsToAdd);
const withCondition = (condition: (params: any) => any) => (query: any) => defaultParams(query, condition(query));

const tasksLink = (r: any) => r && r.id ? '#/taskboard/t/v/' + r.id : 'javascript:void(0)';
const requestsLink = (r: any) => r && r.id ? '#/taskboard/r/v/' + r.id : 'javascript:void(0)';
const jobsLink = (r: any) => r && r.id ? '#/taskboard/j/v/' + r.id : 'javascript:void(0)';
const dpaLink = (r: any) => r && r.id ? '#/taskboard/d/v/' + r.id : 'javascript:void(0)';
const dpaAptLink = (r: any) => r && r.id ? '#/taskboard/d/v/' + r.id : 'javascript:void(0)';

const MAX_COLUMN_WIDTH = 600;
const MIN_COLUMN_WIDTH = 75;

const firstElem: (nl: NodeList) => HTMLElement = (nl: NodeList) => {
  if (!nl) {
    return;
  }
  for (let i = 0; i < nl.length; i++) {
    const n = nl[i];
    if (n.nodeType === Node.ELEMENT_NODE) {
      return <HTMLElement>n;
    }
  }
};

const calcTextCellWidth = (el: HTMLElement) => {
  const f = firstElem(el.childNodes);
  const ff = firstElem(f.childNodes);
  if (!f) {
    return 0;
  }
  return Math.max(Math.min(ff.offsetWidth + 10 || MAX_COLUMN_WIDTH), MIN_COLUMN_WIDTH);
};

const TERMINAS_WIDTH = 80;

const markIfNotSeen = (row: any) => {
  const state = parseInt(row.stateForUser);
  if (state == 0) {
    return '';
  }
  if (state < 0) {
    return 'not-seen';
  }
  let classList = '';
  if (state & TASK_CLIENT_COMMENTED) {
    classList += 'not-seen task-client-commented';
  }
  if (state & TASK_CHILD_ENTERED) {
    classList += ' task-child-entered';
  }
  if (state & TASK_JOB_ENTERED) {
    classList += ' task-job-entered';
  }
  if (state & TASK_STATE_CHANGED) {
    classList += ' task-state-changed';
  }
  if (state & TASK_EMPLOYEE_COMMENTED) {
    classList += ' not-seen';
  }
  return classList;
};

/*
 * Taskboardo pagrindinis komponentas.
 * Atvaizdavimui naudojama ServerTable - komponentas, apgaubiantis ngx-datatable funkcionalumu, kuris ima duomenis is per nustatymus paduotos funkcijos.
 * Uzklausu formavimui ir siuntimo suzadinimui naudoja ProtoQueryProvider - siuo atveju jis panaudotas direktyvos pavidalu.
 * Taip pat naudojamas ProtoStorage funkcionalumas - tiek serviso, tiek direktyvu pavidalu, tokiu budu issaugojamos busenos atitinkamais nustatymais.
 * Varneliu busenoms naudojama ProtoFlags - abstrakcija, leidzianti implementuoti flagu zymejimo logika paprastomis simboliu eilutemis.
 * Pagal pasirinkta meniu punkta, parenkamas atitinkamas TableModel ir sudeliojami filtrai
 */
@Component({
  selector: 'taskboard-list',
  templateUrl: './taskBoardList.component.html',
  styleUrls: ['./taskboardList.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskboardListComponent implements OnInit, AfterViewInit, OnDestroy {

  loading: any;
  @ViewChildren('filterStrbl') _storable: QueryList<StorableDirective>;
  @ViewChild('searchField') _searchField: ElementRef;
  @ViewChild(QueryProviderDirective) _queryProvider: QueryProviderDirective;
  @ViewChild(ServerTableComponent) _serverTable: ServerTableComponent;
  @ViewChild('headerTmp', { read: TemplateRef, static: true }) headerTmp: TemplateRef<any>;
  @ViewChild('headerCnt', { read: ViewContainerRef }) headerCnt: ViewContainerRef;
  protected _priorityColors = PRIORITY_COLORS;
  protected _priorityAbbr = PRIORITY_ABBR;
  protected _priorityTooltips = PRIORITY_TOOLTIPS;
  protected _selectedRows: Array<any> = [];
  protected _showSearchClear = false;
  protected ctm;  //current table model
  protected _isVisible: boolean = false;
  protected _tm: TableModel;
  protected _currentPage: number = 3;
  private _pageSavingOptions: StorageOptions = {
    popState: true,
    refresh: true
  };
  monthStartDay: string;
  monthEndDay: string;

  public isFocused: boolean;
  private _lastId: any;
  private _colSettings: Array<any> = [];
  private _now = new Date();
  //Nustatymai skirti lenteles filtravimui, paieskai, cia nurodomi nustatymai ProtoFlagsGroup komponentui
  private tasksCtm = {
    isTasks: true,
    hasButton: true,
    showTaskTypeFilter: true,
    new: ['../', NEW],
    hasDateInterval: true,
    filtering: {
      flagsGroup: '11000000000',
      rules: {
        '00000000000': '11000000000',
      },
      flags: [
        {
          flag: 'new',
          label: 'Naujos'
        },
        {
          flag: 'ownerCommented',
          label: 'U-Patikslintos'
        },
        {
          flag: 'seen',
          label: 'Peržiūrėtos'
        },
        // {
        //   flag: 'distributed',
        //   label: 'Paskirstytos'
        // },
        {
          flag: 'completed',
          label: 'Atliktos'
        },
        {
          flag: 'finished',
          label: 'Baigtos'
        },
        {
          flag: 'nonCompleted',
          label: 'Neatliktos'
        },
        {
          flag: 'rejected',
          label: 'Atmestos'
        },
        {
          flag: 'returnToAnalytic',
          label: 'Grąžinta analitikui'
        },
        {
          flag: 'postponed',
          label: 'Atidėta'
        },
        {
          flag: 'won',
          label: 'Laimėta'
        },
        {
          flag: 'testing',
          label: 'Testavimas'
        },
      ]
    }
  };

  private jobsCtm = {
    hasButton: true,
    hasDateInterval: true,
    hasSentDpaFilter: true,
    filtering: {
      flagsGroup: '11',
      rules: {
        '00': '11'
      },
      flags: [
        {
          flag: 'withDpa',
          label: 'Priskirti DPA'
        },
        {
          flag: 'noDpa',
          label: 'Nepriskirti DPA'
        }
      ]
    },
    jobsFiltering: {
      flagsGroup: '11',
      rules: {
        '00': '11'
      },
      flags: [
        {
          flag: 'sentDpa',
          label: 'Išsiųstas DPA'
        },
        {
          flag: 'notSentDpa',
          label: 'Neišsiųstas DPA'
        }
      ]
    }
  };

  private requestsCtm = {
    hasButton: this._canEnterRequest(),
    new: ['../', NEW],
    isTasks: false,
    hasDateInterval: true,
    showTaskTypeFilter: true,
    filtering: {
      flagsGroup: '1100000000',
      rules: {
        '0000000000': '1100000000'
      },
      flags: [
        {
          flag: 'new',
          label: 'Naujos'
        },
        {
          flag: 'ownerCommented',
          label: 'U-Patikslintos'
        },
        {
          flag: 'seen',
          label: 'Peržiūrėtos'
        },
        // {
        //   flag: 'distributed',
        //   label: 'Paskirstytos'
        // },
        {
          flag: 'completed',
          label: 'Atliktos'
        },
        {
          flag: 'finished',
          label: 'Baigtos'
        },
        {
          flag: 'rejected',
          label: 'Atmestos'
        },
        {
          flag: 'returnToAnalytic',
          label: 'Grąžinta analitikui'
        },
        {
          flag: 'postponed',
          label: 'Atidėta'
        },
        {
          flag: 'won',
          label: 'Laimėta'
        },
        {
          flag: 'testing',
          label: 'Testavimas'
        },
      ]
    }
  };

  private dpaCtm = {
    hasDateInterval: true,
    // showDPAGroups: true,
    filtering: {
      flagsGroup: '1010',
      rules: {
        '0000': '1111',
        //TODO: padaryti parseri su wildcardais, kad nereiketu apsirasineti tokiu kombinaciju, pvz **00 -> **11
        '0100': '0111', '1000': '1011', '1100': '1111', '0010': '1110', '0001': '1101', '0011': '1111'
      },
      flags: [
        {
          flag: 'paid',
          label: 'Apmokami'
        },
        {
          flag: 'nonPaid',
          label: 'Neapmokami'
        },
        {
          flag: 'notFinished',
          label: 'Neužbaigti'
        },
        {
          flag: 'finished',
          label: 'Užbaigti'
        }
      ]
    }
  };

  private servicedpaCtm = {
    hasDateInterval: true,
    hasDpaTypes: true,
    dateToActive: true,
    // dateIntervalMonthDefault: true,
    // showGroups: true,
    // overtimeFlag: {
    //   flag: 'overtime',
    //   label: 'Tik viršyto laiko DPA'
    // },
    overtimeFlag: {
      flagsGroup: '0',
      rules: {
      },
      flags: [
        {
          flag: 'exceededTime',
          label: 'Tik viršyto laiko DPA'
        },
      ]
    },
    filtering: {
      flagsGroup: '10',
      rules: {
        '00': '11',
        //TODO: padaryti parseri su wildcardais, kad nereiketu apsirasineti tokiu kombinaciju, pvz **00 -> **11
        '10': '10', '01': '01', '11': '11'
      },
      flags: [
        {
          flag: 'notFinished',
          label: 'Neužbaigti'
        },
        {
          flag: 'finished',
          label: 'Užbaigti'
        }
      ]
    }
  };

  private _tableConstructors = {
    myTasks: ({
      ...this.tasksCtm,
      showStreams: true,
      id: 'myTasks',
      title: 'Mano įvestos užduotys'
    }),
    myJobs: ({
      ...this.jobsCtm,
      id: 'myJobs',
      title: 'Mano darbai'
    }),
    myDpa: ({
      ...this.dpaCtm,
      id: 'myDpa',
      title: 'DPA'
    }),
    myGroupDpa: ({
      ...this.dpaCtm,
      showDPAGroups: true,
      id: 'myGroupDpa',
      title: 'DPA'
    }),
    myDpaApt: ({
      ...this.servicedpaCtm,
      showDPAGroups: true,
      id: 'myDpaApt',
      title: 'Procesų DPA'
    }),
    partnerDpa: ({
      ...this.dpaCtm,
      id: 'partnerDpa',
      title: 'Partnerio DPA'
    }),
    partnerDpaApt: ({
      ...this.servicedpaCtm,
      showGroups: true,
      id: 'partnerDpaApt',
      title: 'Partnerio Priež. DPA'
    }),
    tasksForMe: ({
      ...this.tasksCtm,
      showStreams: true,
      taskPersonsType: {
        flagsGroup: '00',
        flags: [
          {
            flag: 'onlyDelegate',
            weight: '!0',
            label: 'Dalyvis'
          },
          {
            flag: 'onlyWatcher',
            weight: '0!',
            label: 'Stebėtojas'
          }
        ],
      },
      id: 'tasksForMe',
      title: 'Man įvestos užduotys'
    }),
    tasksForMyGroup: ({
      ...this.tasksCtm,
      // showGroups: true,
      showStreams: true,
      showDPAGroups: true,
      taskPersonsType: {
        flagsGroup: '00',
        flags: [
          {
            flag: 'onlyDelegate',
            weight: '!0',
            label: 'Dalyvis'
          },
          {
            flag: 'onlyWatcher',
            weight: '0!',
            label: 'Stebėtojas'
          }
        ],
      },
      id: 'tasksForMyGroup',
      title: 'Mano grupei įvestos užduotys'
    }),
    requestsForMe: ({
      ...this.requestsCtm,
      taskPersonsType: {
        flagsGroup: '00',
        flags: [
          {
            flag: 'onlyDelegate',
            weight: '!0',
            label: 'Dalyvis'
          },
          {
            flag: 'onlyWatcher',
            weight: '0!',
            label: 'Stebėtojas'
          }
        ],
      },
      id: 'requestsForMe',
      title: 'Man įvestos užklausos'
    }),
    requestsForMyGroup: ({
      ...this.requestsCtm,
      showDPAGroups: true,
      id: 'requestsForMyGroup',
      // showGroups: true,
      title: 'Mano grupei įvestos užklausos'
    }),
    transferedTasks: ({
      ...this.tasksCtm,
      id: 'transferedTasks',
      title: 'Mano perduotos užduotys'
    }),
    transferedRequests: ({
      ...this.requestsCtm,
      id: 'transferedRequests',
      title: 'Mano perduotos užklausos'
    }),
    partnerRequests: ({
      ...this.requestsCtm,
      new: [NEW],
      id: 'partnerRequests',
      title: 'Partnerio užklausos'
    }),
    partnerTasks: ({
      ...this.tasksCtm,
      showStreams: true,
      new: [NEW],
      id: 'partnerTasks',
      title: 'Partnerio užduotys'
    }),
    partnerJobs: ({
      ...this.jobsCtm,
      id: 'partnerJobs',
      title: 'Partnerio darbai'
    }),
    userTasks: ({
      ...this.tasksCtm,
      showStreams: true,
      id: 'userTasks',
      title: 'Vartotojo užduotys'
    }),
    userRequests: ({
      ...this.requestsCtm,
      id: 'userRequests',
      title: 'Vartotojo užklausos'
    }),
    userJobs: ({
      ...this.jobsCtm,
      id: 'userJobs',
      title: 'Vartotojo darbai'
    }),
    userDpa: ({
      ...this.dpaCtm,
      id: 'userDpa',
      title: 'Vartotojo DPA'
    }),
  };
  private _subscriptions: Array<Subscription> = [];
  private _selectedPartnerId: any;
  private _selecteUserId: any;
  private _rowClicks: Subject<any> = new Subject();

  faBars = faBars;
  faPlusSquare = faPlusSquare;

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _chg: ChangeDetectorRef,
    private _stateService: StateService,
    private _tasksBackend: TasksBackend,
    private _jobsBackend: JobsBackend,
    private _selectedPartnerService: SelectedPartnerService,
    private _storageService: StorageService,
    private _permissionsService: NgxPermissionsService,
    private _taskboardSidebar: TaskboardSidebarService,
    private _taskboardDataService: TaskboardDataService,
    private _dpaBackend: DpaBackend,
    private _tplService: TplService,
    private _elementRef: ElementRef) {
  }

  @ViewChildren('dropdown') dropdown: QueryList<BsDropdownDirective>;

  ngOnInit() {
    this._subscriptions.push(this._selectedPartnerService.selectedPartner.subscribe((sp) => {
      if (sp.isEmpty) {
        this._selectedPartnerId = sp.partner.id;
        this._selecteUserId = sp.partner.id;
      } else {
        if (!sp.isUser) {
          this._selectedPartnerId = sp.partner.id;
        } else {
          this._selecteUserId = sp.partner.id;
        }
      }
    }));
    // this._subscriptions.push(this._selectedPartnerService.selectedUser.subscribe((us) => {
    //   this._selecteUserId = us.id;
    // }));
    const today = new Date();
    this.monthStartDay = new Date(today.getFullYear(), today.getMonth(), 1).toLocaleDateString('LT');
    this.monthEndDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).toLocaleDateString('LT');
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(this.dropdown.changes.subscribe((comps: QueryList<BsDropdownDirective>) => {
      if (comps.first) {
        comps.first.show();
        comps.first.hide();
      }
    }));
    this._initTableModel();
    this._subscriptions.push(this._stateService.getStream(JOB_ENTERED).subscribe((j) => {
      this._serverTable.refreshData();
    }));
    this._subscriptions.push(this._stateService.getStream(UPDATE_TASKBOARD_LIST).subscribe((fn) => {
      // this._serverTable.updateRecords(fn);
      this._serverTable.refreshData();
    }));
    this._subscriptions.push(
      this._rowClicks.pipe(
        withLatestFrom(
          this._route.params.pipe(pluck(TYPE)).pipe(
            withLatestFrom(
              this._route.queryParams.pipe(pluck(PARTNER)),
              (p, q) => ({
                params: p,
                partner: q
              })
            )
          ),
          (row, t) => ({
            row: row,
            t: t
          })
        )
        , filter((rt) => rt.t != null && rt.row && rt.row.id)
      )
        .subscribe((rt) => {
          if ((<any>(rt.t)).params != JOBS && !(<any>(rt.t)).partner && (<any>(rt.t)).params != DPA && (<any>(rt.t)).params != GROUPDPA && (<any>(rt.t)).params != DPAAPT) {
            this._router.navigate(['../', REVIEW, rt.row.id], { relativeTo: this._route });
          } else {
            if ((<any>(rt.t)).params == DPAAPT) {
              this._router.navigate(['/taskboard', DPA, REVIEW, rt.row.id], {
                queryParams: { [PARTNER]: (<any>(rt.t)).partner }
              });
            } else {
              this._router.navigate([REVIEW, rt.row.id], {
                relativeTo: this._route,
                queryParams: { [PARTNER]: (<any>(rt.t)).partner }
              });
            }
          }
        }));
    this._subscriptions.push(this._route.data.pipe(delay(0)).subscribe((d) => {
      this._isVisible = false;
      this._selectedRows = [];
      //Parenkamas atitinkamas konstruktorius
      this.ctm = this._tableConstructors[d.tableType];
      if (!this.ctm) {
        return;
      }
      //Atstatomas pasirinktas eilutes ID, tam, kad matytusi ant kurios eilutes buvo paspausta
      this._lastId = this._storageService.get(this.ctm.id, null, {
        popState: true,
        static: true
      });
      //Pakraunami pasirinkti stulpeliai ir ju plociai
      let colSettings = this._storageService.get(this.ctm.id + '.colSettings') || [];
      let sortSettings = this._storageService.get(this.ctm.id + '.sort');
      //Gaunamas reikiamas TableModel
      let __tm = this._tModels.find((tm) => tm.name == d.tableType);

      //Aplungiami TableModel nustatymai, su issaugotais atmintyje
      for (let i = 0; i < __tm.columns.length; i++) {
        let cs = colSettings.find((s) => s.name == __tm.columns[i].name);
        if (cs) {
          __tm.columns[i].visible = cs.visible != undefined ? cs.visible : true;
          __tm.columns[i].width = cs.width || __tm.columns[i].width || 200;
          __tm.columns[i].index = cs.index || i;
        } else {
          __tm.columns[i].width = __tm.columns[i].width || 200;
          __tm.columns[i].index = i;
        }
      }
      if (sortSettings && sortSettings.prop) {
        let _c = __tm.columns.find((c) => c.prop == sortSettings.prop);
        if (_c) {
          let _dc = __tm.columns.find((c) => !!c.sorted);
          if (_dc) {
            delete _dc['sorted'];
          }
          _c.sorted = { order: sortSettings.order };
        }
      } else {
      }
      this._colSettings = colSettings;
      this._tm = __tm;
      //Perkraunama lentele
      try {
        this._chg.detectChanges();
      } catch (err) { }
      this._isVisible = true;
      this._currentPage = this._storageService.get('currentPage', 0, this._pageSavingOptions);
      try {
        this._chg.detectChanges();
      } catch (err) { }
      //Atstamoma paieskos lauko reiksme
      this._searchField ? this._searchField.nativeElement.value = this._storageService.get(this.ctm.id + '.search', undefined, {
        popState: true,
        refresh: true,
        sameRoute: true,
      }) || '' : null;
      this._searchField && this._searchField.nativeElement.value != '' ? this._searchField.nativeElement.dispatchEvent(new Event('keyup')) : {};

      const sortingQp = this._serverTable && this._serverTable.getSortingQueryPart();
      this._queryProvider && this._queryProvider.registerQueryPart(sortingQp);

      const pagingQp = this._serverTable && this._serverTable.getPagingQueryPart();
      this._queryProvider && this._queryProvider.registerQueryPart(pagingQp);

      if (this._queryProvider) this._queryProvider.active = true;
      //Trigerinama paieska
      this._queryProvider && this._queryProvider.emit('page');
      this._queryProvider && this._queryProvider.emit('sort');
      this._queryProvider && this._queryProvider.emit('search');
    }));
    this._subscriptions.push(this._taskboardDataService.refreshDatatable.subscribe((e) => {
      this._queryProvider.emit('search');
    }));
  }

  _onSort(e) {
    this._storageService.store(this.ctm.id + '.sort', {
      prop: e.prop,
      order: e.order
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  protected _sortingMapper = (v) => {
    let col = v.prop ? this._tm.columns.find((c) => c.prop == v.prop) : this._tm.columns.find((c) => c.sortable !== false);
    return col ? ({
      orderBy: col.prop,
      orderDir: v.order
    }) : v;
  };

  protected _recalcColumns() {
    const table = this._serverTable;
    if (table) {
      table.calculateColumns();
    }
    try {
      this._chg.detectChanges();
    } catch (err) { }
  }

  protected _pageChanged(page) {
    this._currentPage = page;
    this._storageService.store('currentPage', page, this._pageSavingOptions);
  }

  // protected _untilToday({ toChanged, ...event }, dateToActive, localStorageKey)
  protected _untilToday(event: DateInterval, dateToActive, localStorageKey) {
    if (this.toToday(dateToActive, localStorageKey, event.toChanged)) {
      return {
        ...event,
        to: 'today'
      };
    } else {
      return { ...event };
    }
  }

  setToday(localStorageKey) {
    localStorage.setItem(localStorageKey, '1');
    this._taskboardDataService.setDateToToday.emit(true);
  }

  checkIsToday(localStorageKey) {
    const toTodayL = +localStorage.getItem(localStorageKey);
    return toTodayL === 1;
  }

  toToday(dateToActive, localStorageKey, toChanged) {
    if (dateToActive) {
      const toTodayL = +localStorage.getItem(localStorageKey);
      if (toTodayL === 1 && !toChanged) {
        return true
      } else {
        if (toChanged) {
          localStorage.setItem(localStorageKey, '0');
        }
        return false
      }
    } else {
      return true;
    }
  }

  reloadPageOnCondition(params, resp) {
    if (resp.entities.length === 0 && params.page !== 0 && params.page !== undefined && params.page !== null) {
      this._pageChanged(0);
      location.reload();
    }
  }

  private makeQuery = (query: string) => (additionalParams: (q: any) => any) => (params: any) => { //TODO split to separate methods by 'query' parameter
    const p = additionalParams(params);
    switch (query) {
      case 'getPartnerJobs':
        p['type'] = 1;
        return this._jobsBackend.getJobsList(p || params).pipe(map((resp: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities,
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getUserJobs':
        return this._jobsBackend.getJobsList(p || params).pipe(map((resp: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities,
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getMyJobs':
        return this._jobsBackend.getJobsList(p || params).pipe(map((resp: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities,
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getDpa':
        return this._dpaBackend.getDpaList({ dpaType: 3, ...(p || params) }).pipe(map((resp: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities,
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getGroupDpa':
        return this._dpaBackend.getDpaList(p || params).pipe(map((resp: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities,
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getDpaApt':
        const pp = p || params;
        // return this._dpaBackend.getDpaList({ ...pp, dpaType: 1 }).pipe(map((entities: EntityList<any>) => {
        return this._dpaBackend.getDpaList({ dpaType: 1, ...pp }).pipe(map((resp: EntityList<any>) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities,
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getMyTasks':
        return this._tasksBackend.getTasksList(MY_TASKS_TYPE, null, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getTasksForMe':
        return this._tasksBackend.getTasksList(TASKS_FOR_ME_TYPE, null, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getTasksForMyGroup':
        return this._tasksBackend.getTasksList(TASKS_FOR_MY_GROUP_TYPE, null, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getPartnerTasks':
        return this._tasksBackend.getTasksList(TASKS_FOR_MY_GROUP_TYPE, -1, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getUserTasks':
        return this._tasksBackend.getTasksList(TASKS_FOR_ME_TYPE, -1, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getRequestsForMe':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_ME_TYPE, null, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getPartnerRequests':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_MY_GROUP_TYPE, -1, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getUserRequests':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_ME_TYPE, -1, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getRequestsForMyGroup':
        return this._tasksBackend.getTasksList(REQUESTS_FOR_MY_GROUP_TYPE, null, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getTransferedTasks':
        return this._tasksBackend.getTasksListTransfers(TASKS_TRANSFERS_TYPE, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
      case 'getTransferedRequests':
        return this._tasksBackend.getTasksListTransfers(REQUESTS_TRANSFERS_TYPE, p || params).pipe(map((resp) => {
          this.reloadPageOnCondition(p || params, resp);
          return {
            data: resp.entities.map(t => ({ ...t, isActive: isActiveState(t.stateId) })),
            count: resp.recordsCount
          }
        }), tap(this._applySelected), tap(r => this._setActiveList(query, p, r)));
    }

  };

  private _applySelected = (res) => {
    if (!res || !res.data || res.data.length < 1) {
      return;
    }
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].id == this._lastId) {
        this._selectedRows.push(res.data[i]);
        return;
      }
    }
  };

  private _setActiveList = (list: string, params: any, tasks: any) => {
    this._taskboardDataService.setActiveList(list, params, tasks);
  };

  private _applyDpaFilter = (qr) => {
    if (qr.paid && qr.nonPaid) {
      delete qr['paid'];
      delete qr['nonPaid'];
    } else if (qr.nonPaid) {
      delete qr['nonPaid'];
      qr.paid = 0;
    } else if (qr.paid) {
      qr.paid = 1;
    }
    if (qr.finished && !qr.notFinished) {
      qr['stateFilter'] = 1;
    } else if (qr.notFinished && !qr.finished) {
      qr['stateFilter'] = 2;
    }
    delete qr['finished'];
    delete qr['notFinished'];
    if (this._selectedPartnerId > 0) {
      qr['partnerId'] = this._selectedPartnerId;
    }
    if (this._selecteUserId > 0) {
      qr['userId'] = this._selecteUserId;
    }
    return qr;
  };

  private _applyJobFilter = (qr) => {
    if (qr.withDpa && qr.noDpa) {
      delete qr['withDpa'];
      delete qr['noDpa']
    } else if (qr.withDpa) {
      delete qr['noDpa'];
      qr.withDpa = 1;
    } else if (qr.noDpa) {
      delete qr['noDpa'];
      qr.withDpa = 0;
    }
    if (this._selectedPartnerId > 0) {
      qr['partnerId'] = this._selectedPartnerId;
    }
    if (this._selecteUserId > 0) {
      qr['userId'] = this._selecteUserId;
    }
    return qr;
  };

  //Nustatymai, skirti ServerTable funkcionalumui (isskyrus viska, kas yra uz lenteles ribu: - filtrai, paieska ir tt..)
  private _tModels: Array<any>;

  private _initTableModel() {
    const tpls = this._tplService;

    const COL_PARTNER = {
      name: 'Partneris',
      prop: 'partner',
      width: 190,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.partnerTpl,
    };
    const COL_START_DATE = {
      name: 'Vykdymo data',
      prop: 'startDate',
      width: 190,
      cellTemplate: tpls.startDateTpl,
      getWidth: calcTextCellWidth
    };
    const COL_EXECUTOR = {
      name: 'Vykdytojas',
      prop: 'executor',
      width: 190,
      cellTemplate: tpls.executorTpl,
      getWidth: calcTextCellWidth
    };
    const COL_EXECUTOR_EDITABLE = {
      name: 'Vykdytojas',
      prop: 'executor',
      width: 190,
      cellTemplate: tpls.executorEditableTpl,
      getWidth: calcTextCellWidth
    };
    const COL_OWNER = {
      name: 'Užsakovas',
      prop: 'owner',
      width: 190,
      getWidth: calcTextCellWidth
    };
    const COL_TASK_CODE = {
      name: 'Užduotis',
      prop: 'taskCode',
      width: 140,
      cellTemplate: tpls.taskCodeTpl,
      getWidth: calcTextCellWidth,
    };
    const COL_IS_FAVORITE = {
      name: '    ',
      prop: 'isFavorite',
      width: 50,
      optional: false,
      sortable: false,
      cellTemplate: tpls.isFavoriteTpl,
      headerTemplate: tpls.isFavoriteHeaderTpl,
      getWidth: (_) => 50,
    };
    const COL_DPA_MAIL_STATE = {
      name: 'Išsiuntimo būsena',
      prop: 'lastSendDate',
      width: 160,
      optional: true,
      sortable: true,
      cellTemplate: tpls.DPAMailStateTpl,
      getWidth: calcTextCellWidth,
    };
    const COL_DATE_ENTERED = {
      name: 'Data',
      prop: 'dateEntered',
      width: 110,
      sorted: { order: 'desc' },
      getWidth: (_) => 110,
      cellTemplate: tpls.dateEnteredTpl
    };
    const COL_DEADLINE = {
      name: 'Terminas',
      prop: 'deadline',
      width: 85,
      getWidth: (_) => 80,
      cellTemplate: tpls.deadlineTpl
    };
    const COL_DESCRIPTION = {
      name: 'Aprašymas',
      prop: 'description',
      width: 200,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.descriptionTpl
    };
    const COL_PRIORITY = {
      name: 'Prioritetas',
      prop: 'priority',
      headerClass: 'center',
      class: 'center',
      width: 100,
      getWidth: (_) => TERMINAS_WIDTH,
      cellTemplate: tpls.priorityTpl
    };
    const COL_TYPE = {
      name: 'Veiklos tipas',
      prop: 'type',
      width: 105,
      getWidth: calcTextCellWidth,
    };
    const COL_TYPE_TASKS = {
      name: 'Tipas',
      prop: 'type',
      width: 105,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.taskTypeTpl,
    };
    const COL_STATE = {
      name: 'Būsena',
      prop: 'state',
      headerClass: 'center',
      width: 75,
      getWidth: (_) => 75,
      cellTemplate: tpls.stateTpl
    };
    const COL_JOB_BTNS = {
      name: ' ',
      prop: 'id1',
      optional: false,
      width: 100,
      sortable: false,
      getWidth: (_) => 100,
      cellTemplate: tpls.jobButtonsTpl
    };

    const COL_STATE_FOR_USER = {
      name: '  ',
      prop: 'id2',
      optional: false,
      width: 75,
      sortable: false,
      getWidth: (_) => 75,
      cellTemplate: tpls.stateForUserTpl,
      headerTemplate: tpls.stateForUserHeaderTpl,
    };

    const COL_DELEGATE = {
      name: 'Atstovas',
      prop: 'delegate',
      class: 'r-3-col-wdth',
      headerClass: 'r-3-header-wdth',
      orderId: 4,
      getWidth: calcTextCellWidth
    };
    const COL_NEAREST_ACTION = {
      name: 'Veiksmai',
      prop: 'nearestAction',
      class: 'r-7-col-wdth',
      headerClass: 'r-7-header-wdth',
      width: 90,
      getWidth: (_) => 90,
      cellTemplate: tpls.nearestActionTpl
    };
    const COL_PRODUCT = {
      name: 'Produktas',
      prop: 'product',
      width: 100,
      getWidth: calcTextCellWidth
    };
    const COL_REACTION_TIME = {
      name: 'Reag. laikas',
      prop: 'reactionTime',
      headerClass: 'reaction-time',
      width: 60,
      getWidth: (_) => 60,
      headerTemplate: tpls.reactionTpl
    };
    const COL_LAST_STATE_CHANGE = {
      name: 'Paskutinis būs. keitimas',
      prop: 'lastStateChange',
      class: 'last-state-change',
      headerClass: 'last-state-change',
      width: 110,
      getWidth: (_) => 110,
      headerTemplate: tpls.lastStateChangeTpl,
      cellTemplate: tpls.lastStateChangeCellTpl
    };

    const COL_DELEGATE_EMAIL = {
      name: 'Atstovo el. paštas',
      prop: 'delegateEmail',
      headerClass: 'd-header-wdth',
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.delegateEmailTpl
    };
    const COL_LONG_DATE = {
      name: 'Data',
      prop: 'date',
      width: 110,
      sorted: { order: 'desc' },
      getWidth: (_) => 110,
      cellTemplate: tpls.dateTpl
    };
    const COL_SHORT_DATE = {
      name: 'Data',
      prop: 'date',
      width: 110,
      sorted: { order: 'desc' },
      getWidth: (_) => 110,
      cellTemplate: tpls.shortDateTpl
    };
    const COL_IS_PAID = {
      name: 'Apmokamas',
      prop: 'isPaid',
      headerClass: 'center',
      class: 'center',
      getWidth: (_) => 100,
      cellTemplate: tpls.isPaidTpl
    };
    const COL_DPA_CODE = {
      name: 'Numeris',
      prop: 'dpaCode',
      width: 160,
      getWidth: () => 160,
    };
    const COL_DPA_SF_NR = {
      name: 'S.F. Numeris',
      prop: 'sfNr',
      headerClass: 'center',
      width: 93,
      getWidth: (_) => 93,
    };
    const COL_DPA_FINISHED = {
      name: 'Būsena',
      prop: 'isFinished',
      headerClass: 'center',
      width: 75,
      getWidth: (_) => 75,
      cellTemplate: tpls.isDpaFinishedTpl
    };
    const COL_DPA_FINISHED_JOBS = {
      name: 'DPA būsena',
      prop: 'isFinished',
      headerClass: 'center',
      width: 100,
      getWidth: (_) => 100,
      cellTemplate: tpls.isDpaFinishedTpl
    };
    const COL_SERVICE = {
      name: 'Paslauga',
      prop: 'service',
      width: 230,
      getWidth: calcTextCellWidth
    };
    const COL_JOB_TIME = {
      name: 'Laikas',
      prop: 'timeRange',
      width: 90,
      sortable: false,
      getWidth: (_) => 90
    };
    const COL_JOB_DURATION = {
      name: 'Kliento laikas',
      prop: 'duration',
      width: 115,
      sortable: false,
      getWidth: (_) => 75
    };
    const COL_JOB_SUM = {
      name: 'Suma',
      prop: 'sumNoVat',
      width: 65,
      getWidth: (_) => 65
    };
    const COL_OLD_EXECUTOR = {
      name: 'Buvęs vykdytojas',
      prop: 'oldExecutor',
      headerClass: 't-1-header-wdth',
      orderId: 3,
      getWidth: calcTextCellWidth
    };
    const COL_NEW_EXECUTOR = {
      name: 'Naujas vykdytojas',
      prop: 'newExecutor',
      class: 't-1-col-wdth',
      headerClass: 't-1-header-wdth',
      orderId: 4,
      getWidth: calcTextCellWidth
    };
    const COL_TRANSFER_DATE = {
      name: 'Perdavimo data',
      prop: 'date',
      class: 't-2-col-wdth',
      headerClass: 't-2-header-wdth',
      orderId: 3,
      sorted: { order: 'desc' },
      getWidth: (_) => 110,
      cellTemplate: tpls.dateTpl
    };
    const COL_TRANSFER_DESCRIPTION = {
      name: 'Perdavimo pastaba',
      prop: 'transferDescription',
      class: 't-7-col-wdth',
      headerClass: 't-7-header-wdth',
      orderId: 5,
      getWidth: calcTextCellWidth
    };

    const COL_REQUEST_CODE = {
      name: 'Kodas',
      prop: 'code',
      width: 170,
      getWidth: calcTextCellWidth,
    };

    const COL_LONG_DATE_DPA = {
      name: 'Sukūrimo Data',
      prop: 'date',
      width: 120,
      sorted: { order: 'desc' },
      getWidth: (_) => 120,
      cellTemplate: tpls.dateTpl
    };

    const COL_PROJECTS_LINK = {
      name: '   ',
      prop: 'id3',
      width: 40,
      optional: false,
      sortable: false,
      getWidth: (_) => 40,
      cellTemplate: tpls.projectsLinkTpl,
      headerTemplate: tpls.isProjectHeaderTpl,
    };

    const COL_REQUESTS_UNDO_SEEN = {
      name: '     ',
      prop: 'id4',
      width: 40,
      optional: false,
      sortable: false,
      getWidth: (_) => 40,
      cellTemplate: tpls.undoSeenTpl,
      headerTemplate: tpls.undoSeenHeaderTpl,
    };

    const COL_SHORT_DATE_DPA = {
      name: 'Sukūrimo Data',
      prop: 'date',
      width: 120,
      sorted: { order: 'desc' },
      getWidth: (_) => 120,
      cellTemplate: tpls.shortDateTpl
    };

    const COL_LONG_DATE_DPA_END = {
      name: 'Užbaigimo Data',
      prop: 'endDate',
      width: 130,
      sortable: true,
      getWidth: (_) => 130,
      cellTemplate: tpls.endDateTpl
    };

    const COL_TOTAL_TIME_DPA = {
      name: 'Viršytas laikas',
      prop: 'totalTime',
      width: 120,
      sortable: true,
      getWidth: (_) => 120,
    };

    const COL_PRODUCT_DPA = {
      name: 'Produktas',
      prop: 'product',
      width: 170,
      getWidth: calcTextCellWidth,
    };

    const COL_SYSNAME_DPA = {
      name: 'Tipas',
      prop: 'dpaSysName',
      sortable: false,
      width: 170,
      getWidth: calcTextCellWidth,
    };

    const COL_ACTUAL_CONSUMPTION = {
      name: 'Faktinės sąnaudos',
      prop: 'actualConsuming',
      sortable: false,
      width: 140,
      getWidth: calcTextCellWidth,
      cellTemplate: tpls.actualConsumptionTpl,
    };
    const COL_EXPECTED_CONSUMPTION = {
      name: 'Numatytos sąnaudos',
      prop: 'expectedConsuming',
      sortable: false,
      width: 150,
      getWidth: calcTextCellWidth,
    };
    const COL_REAL_CONSUMPTION = {
      name: 'Tikėtinos sąnaudos',
      prop: 'realConsuming',
      sortable: false,
      width: 120,
      getWidth: calcTextCellWidth,
    };
    const COL_QUEUE = {
      name: 'Eilė',
      prop: 'taskQueue',
      sortable: true,
      width: 120,
      getWidth: calcTextCellWidth,
    };
    const COL_STREAM_NAME = {
      name: 'Srautas',
      prop: 'streamName',
      sortable: false,
      width: 120,
      getWidth: calcTextCellWidth,
    };

    const _TASKS_COLUMNS = [COL_PROJECTS_LINK, COL_REQUESTS_UNDO_SEEN, COL_STATE_FOR_USER, { ...COL_TASK_CODE, prop: 'code', name: 'Kodas', cellTemplate: undefined }, COL_PARTNER, COL_EXECUTOR_EDITABLE, COL_DATE_ENTERED, COL_PRODUCT, COL_START_DATE, COL_DEADLINE, COL_DESCRIPTION, COL_PRIORITY, COL_LAST_STATE_CHANGE, COL_TYPE_TASKS, COL_STATE, COL_NEAREST_ACTION, COL_QUEUE, COL_STREAM_NAME, COL_ACTUAL_CONSUMPTION, COL_EXPECTED_CONSUMPTION, COL_REAL_CONSUMPTION, COL_JOB_BTNS];

    const _REQUEST_COLUMNS = [COL_PROJECTS_LINK, COL_REQUESTS_UNDO_SEEN, COL_STATE_FOR_USER, COL_REQUEST_CODE, COL_PARTNER, COL_EXECUTOR_EDITABLE, COL_DELEGATE, COL_DATE_ENTERED, COL_START_DATE, COL_DEADLINE, COL_STATE, COL_NEAREST_ACTION, COL_PRODUCT, COL_PRIORITY, COL_LAST_STATE_CHANGE, COL_REACTION_TIME, COL_TYPE, COL_DESCRIPTION, COL_JOB_BTNS];

    const _JOB_COLUMNS = [COL_SHORT_DATE, COL_TASK_CODE, COL_JOB_TIME, COL_JOB_DURATION, COL_JOB_SUM, COL_PARTNER, COL_SERVICE, COL_STATE, COL_IS_PAID, {
      ...COL_DPA_CODE,
      cellTemplate: tpls.dpaCodeTpl
    }, COL_DPA_FINISHED_JOBS, COL_DPA_MAIL_STATE, COL_DESCRIPTION];

    const _DPA_COLUMNS = [COL_DPA_CODE, COL_SHORT_DATE_DPA, COL_LONG_DATE_DPA_END, COL_PARTNER, COL_DPA_MAIL_STATE, COL_DELEGATE_EMAIL, COL_DPA_SF_NR, COL_IS_PAID, COL_DPA_FINISHED, {
      name: '',
      headerClass: 'center',
      class: 'center',
      prop: 'id1',
      cellTemplate: tpls.dpaOptionsTpl,
      optional: false,
      sortable: false,
      getWidth: (_) => 100
    }];

    const _DPAAPT_COLUMNS = [COL_DPA_CODE, COL_SHORT_DATE_DPA, COL_LONG_DATE_DPA_END, COL_TOTAL_TIME_DPA, COL_PARTNER, COL_DPA_MAIL_STATE, COL_PRODUCT_DPA, COL_DELEGATE_EMAIL, COL_DPA_SF_NR, COL_IS_PAID, COL_DPA_FINISHED, COL_SYSNAME_DPA, {
      name: '',
      headerClass: 'center',
      class: 'center',
      prop: 'id2',
      cellTemplate: tpls.dpaOptionsTpl,
      optional: false,
      sortable: false,
      getWidth: (_) => 100
    }];

    const _COL_TRANSFERS = [COL_PARTNER, COL_OLD_EXECUTOR, COL_NEW_EXECUTOR, COL_DESCRIPTION, COL_PRODUCT, COL_TRANSFER_DATE, COL_START_DATE, COL_TRANSFER_DESCRIPTION, COL_PRIORITY, COL_STATE, COL_JOB_BTNS];

    this._tModels = [
      {
        name: 'myTasks',
        linkProvider: tasksLink,
        columns: [COL_IS_FAVORITE, ..._TASKS_COLUMNS],
        getRowClass: markIfNotSeen,
        getData: (q) => (this.makeQuery('getMyTasks')(noAddition)(q))
      },
      {
        name: 'myJobs',
        linkProvider: jobsLink,
        columns: _JOB_COLUMNS,
        getData: (q) => this.makeQuery('getMyJobs')(withCondition(this._applyJobFilter))(q)
      },
      {
        name: 'partnerJobs',
        linkProvider: jobsLink,
        columns: [_JOB_COLUMNS[0], COL_EXECUTOR, ..._JOB_COLUMNS.slice(1)],
        getData: (q) => this.makeQuery('getPartnerJobs')(withCondition(this._applyJobFilter))(q)
      },
      {
        name: 'userJobs',
        linkProvider: jobsLink,
        columns: [_JOB_COLUMNS[0], COL_EXECUTOR, ..._JOB_COLUMNS.slice(1)],
        getData: (q) => this.makeQuery('getUserJobs')(withCondition(this._applyJobFilter))(q)
      },
      {
        name: 'myDpa',
        linkProvider: dpaLink,
        onRowClick: (row: any) => {
          window.open(window.location.origin + '/dpa/' + row.id)
        },
        columns: [..._DPA_COLUMNS.slice(0, 3), ..._DPA_COLUMNS.slice(2)],
        getData: (q) => this.makeQuery('getDpa')(withCondition(this._applyDpaFilter))(q)
      },
      {
        name: 'myGroupDpa',
        linkProvider: dpaLink,
        onRowClick: (row: any) => {
          window.open(window.location.origin + '/dpa/' + row.id)
        },
        columns: [..._DPA_COLUMNS.slice(0, 3), COL_EXECUTOR, ..._DPA_COLUMNS.slice(2)],
        getData: (q) => this.makeQuery('getGroupDpa')(withCondition(this._applyDpaFilter))(q)
      },
      {
        name: 'myDpaApt',
        linkProvider: dpaAptLink,
        onRowClick: (row: any) => {
          window.open(window.location.hostname + '/#/taskboard/d/dpa/' + row.id)
        },
        columns: [..._DPAAPT_COLUMNS.slice(0, 4), ..._DPAAPT_COLUMNS.slice(2)],
        getData: (q) => this.makeQuery('getDpaApt')(withCondition(this._applyDpaFilter))(q)
      },
      {
        name: 'partnerDpa',
        linkProvider: dpaLink,
        onRowClick: (row: any) => {
          window.open(window.location.origin + '/dpa/' + row.id)
        },
        columns: [..._DPA_COLUMNS.slice(0, 3), COL_EXECUTOR, ..._DPA_COLUMNS.slice(2)],
        getData: (q) => this.makeQuery('getDpa')(withCondition(this._applyDpaFilter))(q)
      },
      {
        name: 'userDpa',
        linkProvider: dpaLink,
        onRowClick: (row: any) => {
          window.open(window.location.origin + '/dpa/' + row.id)
        },
        columns: [..._DPA_COLUMNS.slice(0, 3), ..._DPA_COLUMNS.slice(2)],
        getData: (q) => this.makeQuery('getDpa')(withCondition(this._applyDpaFilter))(q)
      },
      {
        name: 'partnerDpaApt',
        linkProvider: dpaAptLink,
        onRowClick: (row: any) => {
          window.open(window.location.hostname + '/#/taskboard/d/dpa/' + row.id)
        },
        columns: [..._DPAAPT_COLUMNS.slice(0, 4), ..._DPAAPT_COLUMNS.slice(2)],
        getData: (q) => this.makeQuery('getDpaApt')(withCondition(this._applyDpaFilter))(q)
      },
      {
        name: 'tasksForMe',
        linkProvider: tasksLink,
        columns: [COL_IS_FAVORITE, _TASKS_COLUMNS[0], _TASKS_COLUMNS[1], COL_OWNER, ..._TASKS_COLUMNS.slice(2)],
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getTasksForMe')(noAddition)(q)
      },
      {
        name: 'tasksForMyGroup',
        linkProvider: tasksLink,
        columns: [COL_IS_FAVORITE, _TASKS_COLUMNS[0], _TASKS_COLUMNS[1], COL_OWNER, ..._TASKS_COLUMNS.slice(2)],
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getTasksForMyGroup')(noAddition)(q)
      },
      {
        name: 'requestsForMe',
        linkProvider: requestsLink,
        columns: [COL_IS_FAVORITE, ..._REQUEST_COLUMNS],
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getRequestsForMe')(noAddition)(q)
      },
      {
        name: 'requestsForMyGroup',
        linkProvider: requestsLink,
        columns: [COL_IS_FAVORITE, ..._REQUEST_COLUMNS],
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getRequestsForMyGroup')(noAddition)(q)
      },
      {
        name: 'transferedTasks',
        linkProvider: tasksLink,
        columns: _COL_TRANSFERS,
        getData: (q) => this.makeQuery('getTransferedTasks')(noAddition)(q)
      },
      {
        name: 'transferedRequests',
        linkProvider: requestsLink,
        columns: _COL_TRANSFERS,
        getData: (q) => this.makeQuery('getTransferedRequests')(noAddition)(q)
      },
      {
        name: 'partnerTasks',
        linkProvider: tasksLink,
        columns: [COL_IS_FAVORITE, ..._TASKS_COLUMNS],
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getPartnerTasks')(withAddition({ partnerId: this._selectedPartnerId }))(q)
      },
      {
        name: 'userTasks',
        linkProvider: tasksLink,
        columns: _TASKS_COLUMNS,
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getUserTasks')(withAddition({ userId: this._selecteUserId }))(q)
      },
      {
        name: 'partnerRequests',
        linkProvider: requestsLink,
        columns: [COL_IS_FAVORITE, ..._REQUEST_COLUMNS],
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getPartnerRequests')(withAddition({ partnerId: this._selectedPartnerId }))(q)
      },
      {
        name: 'userRequests',
        linkProvider: requestsLink,
        columns: _REQUEST_COLUMNS,
        getRowClass: markIfNotSeen,
        getData: (q) => this.makeQuery('getUserRequests')(withAddition({ userId: this._selecteUserId }))(q)
      }
    ].map(m => { m.columns = <any>cloneArr(m.columns); return m });
  }

  private _canEnterRequest() {
    return this._permissionsService.getPermission('insert_request');
  }

  _colVisibilityChanged(col: string, visible: boolean) {
    this._serverTable.setColumnVisibility(col, visible);
    let cs = this._colSettings.find((cs) => cs.name == col);
    if (!cs) {
      this._colSettings.push({
        name: col,
        visible: visible
      });
    } else {
      cs.visible = visible;
    }
    this._storageService.store(this.ctm.id + '.colSettings', this._colSettings);
  }

  _columnResized(e: ColumnResized) {
    let cs = this._colSettings.find((cs) => cs.name == e.name);
    if (!cs) {
      this._colSettings.push({
        name: e.name,
        width: e.newWidth
      });
    } else {
      cs.width = e.newWidth;
    }
    this._storageService.store(this.ctm.id + '.colSettings', this._colSettings);
  }

  _rowClicked(row: any) {
    this._storageService.store(this.ctm.id, row.id, {
      popState: true,
      static: true
    });
    this._rowClicks.next(row);
  }

  public _toggleLeftMenu() {
    this._stateService.notify(TOGGLE_LEFT_MENU);
  }

  _search(val) {
    this._storageService.store(this.ctm.id + '.search', val, {
      popState: true,
      refresh: true
    });
    document.getElementById('content_header_search').blur();
    this._queryProvider.emit('search');
  }

  _clearSearch() {
    this._searchField.nativeElement.value = '';
    this._searchField.nativeElement.dispatchEvent(new Event('keyup'));
    this._queryProvider.emit('search');
    this._storageService.store(this.ctm.id + '.search', undefined, {
      popState: true,
      refresh: true
    });
  }

  _detectSearchVal(val) {
    this._showSearchClear = val.length > 0;
  }

  protected _addNewClicked(id: any) {
    if (id == 'myJobs') {
      this._taskboardSidebar.openJobForm(JobFormModel.newFreeJob(this._taskboardDataService));
    } else if (id == 'partnerJobs') {
      this._taskboardSidebar.openJobForm(JobFormModel.newFreeJob(this._taskboardDataService, this._selectedPartnerId));
    } else {
      this._router.navigate(this.ctm.new, {
        relativeTo: this._route,
        queryParamsHandling: 'preserve'
      });
    }
  }
}
