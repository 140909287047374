
import { throwError } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UserBackend {
  constructor(
    public _api: ApiService, private authService: AuthService
  ) {

  }

  getUserPicture(userId) {
    const userIdPar = userId ? userId : this.authService.user.usersId;
    return this._api.getImage(`users/photo?id=${userIdPar}`);
  }

  getCheckToken() {
    return <any>this._api.getAuth('users/tokenCheck');
  }

  deleteUserPicture() {
    // const userIdPar = userId ? userId : this.authService.user.usersId;
    return this._api.deleteImage(`users/photo?id=${this.authService.user.usersId}`);
  }

  refreshPhoto(userId) {
    return this._api.refreshImage(userId ? `users/photo?id=${userId}` : 'users/photo');
  }

  changeCredentials(credentials) {
    return this._api.postAuth('users/changeCredentials', credentials).pipe(catchError((err) => {
      console.log('caught error', err);
      return throwError(err);
    }),
      map((res: any) => ({ wrongPassword: false, success: true })));
  }

  createUserForDelegate(delegate) {
    return this._api.postAuth('users/createForDelegate', delegate).pipe(
      map((x) => x && x.body)
    );
  }

  activateUser(userId) {
    return <any>this._api.postAuth(`admin/users/${userId}/activate`, null).pipe(map((res: any) => res.body));
  }

  deactivateUser(userId) {
    return <any>this._api.postAuth(`admin/users/${userId}/deactivate`, null).pipe(map((res: any) => res.body));
  }

  editUser(user) {
    return <any>this._api.postAuth(`admin/users/${user.userId}/edit`, user).pipe(map((res: any) => res.body));
  }

  createUser(userData) {
    return this._api.postAuth('users/create', userData);
  }

  changePhoto(photo) {
    return this._api.postAuth('users/photo', { userPhotoBase64: photo });
  }

  private extractFoto(res) {
    let r = res.result;
    r = r[0][0];
    return r && r.Foto ? 'data:image/png;base64, ' + r.Foto : null;
  }

  getUnverifiedUsers(params) {
    return <any>this._api.getAuth('admin/users/notapproved', params).pipe(map((res: any) => res.body.entities));
  }

  getDelegatesList(params) {
    return <any>this._api.getAuth('admin/users/delegates', params).pipe(map((res: any) => res.body));
  }

  getUsers(params) {
    return <any>this._api.getAuth('admin/users/list', params).pipe(map((res: any) => res.body));
  }

  getGoupsUsers(showAll) {
    return <any>this._api.getAuth('users/getGroupUsers', showAll ? { showAll: true } : null).pipe(map((res) => (<any>res).body.entities));
  }

  confirmUser(userId) {
    return <any>this._api.postAuth(`admin/users/${userId}/approve`, null).pipe(map((res: any) => res.body)); // TODO nr1 bad Cannot be tested nobody knows how to get user to "Tvirtinimas" list  mark2
  }
  cancelUserConfirmation(userId) {
    return <any>this._api.postAuth(`admin/users/${userId}/cancel`, null).pipe(map((res: any) => res.body));
  }

  changeUserHomepage(url) {
    return <any>this._api.postAuth('users/homepage', { url }); //TODO nr1 bad paused (disabled functionality)
  }

  getUserDetails(userId) {
    return <any>this._api.getAuth(`admin/users/${userId}`).pipe(map((res: any) => res.body))
  }

  permissionToggle(assigneeId, id, enabled) {
    return this._api.postAuth('admin/users/editClaim', { assigneeId: assigneeId, claimsId: id, enabled: enabled ? '1' : '0' });
  }
}
