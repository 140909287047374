import { BUSENA_ATIDETA, BUSENA_ATLIKTA, BUSENA_ATMESTA, BUSENA_BAIGTA, BUSENA_GRAZINTA, BUSENA_LAIMETA, BUSENA_NAUJA, BUSENA_NEPRIIMTA, BUSENA_PASKIRSTYTA, BUSENA_TESTAVIMAS, BUSENA_U_PATIKSLINTA, BUSENA_VYKDOMA, BUSENA_V_PATIKSLINTA } from './constants';

export function makeGetProp(object: object, defaultError?: string) {
  return function (propName: string, error?: string) {
    if (object.hasOwnProperty(propName)) {
      return object[propName];
    } else {
      throw new Error(error || defaultError || 'Cannot get property ' + propName + ' from object ' + object);
    }
  }
}

export function toDate(val: any): Date {
  // noinspection FallThroughInSwitchStatementJS
  switch (typeof val) {
    case "string":
    case "number":
      return new Date(val);
    case "object":
      if (val instanceof Date) {
        return val;
      }
    default:
      throw new Error('Cannot make Date object from param: ' + val);
  }
}

export function stringOrUndefined(val: any, nonEmpty = true): string | undefined {
  if (typeof val === 'string') {
    if (nonEmpty) {
      return val.replace(' ', '').length > 0 ? val : undefined;
    } else {
      return val;
    }
  }
  return undefined;
}

//Array
export function flatMap(arr: Array<any>, fn: (obj) => Array<any>): Array<any> {
  return <any>arr.map(<any>fn).reduce((acc: Array<any>, curr) => acc.concat(curr), []);
}

export function getStateBitMask(params: any) {
  let mask = 0;
  const nonCompleted = params.nonCompleted === true;
  const completed = params.completed === true;
  const finished = params.finished === true;
  const new1 = params['new'] === true;
  const ownerCommented = params.ownerCommented === true;
  const testing = params.testing === true;
  const seen = params.seen === true;
  const distributed = params.distributed === true;
  const rejected = params.rejected === true;
  const returnToAnalytic = params.returnToAnalytic === true;
  const postponed = params.postponed === true;
  const won = params.won === true;

  if (nonCompleted) {
    mask |= BUSENA_NAUJA | BUSENA_VYKDOMA | BUSENA_V_PATIKSLINTA | BUSENA_U_PATIKSLINTA | BUSENA_NEPRIIMTA;
  }
  if (finished) {
    // mask |= BUSENA_BAIGTA | BUSENA_ATMESTA;
    mask |= BUSENA_BAIGTA;
  }
  if (completed) {
    mask |= BUSENA_ATLIKTA;
  }
  if (seen) {
    // mask |= BUSENA_VYKDOMA | BUSENA_V_PATIKSLINTA | BUSENA_ATIDETA | BUSENA_ATMESTA | BUSENA_NEPRIIMTA;
    // mask |= BUSENA_VYKDOMA | BUSENA_V_PATIKSLINTA | BUSENA_ATIDETA | BUSENA_NEPRIIMTA;
    mask |= BUSENA_VYKDOMA | BUSENA_V_PATIKSLINTA | BUSENA_NEPRIIMTA;
  }
  if (new1) {
    mask |= BUSENA_NAUJA;
  }
  if (ownerCommented) {
    mask |= BUSENA_U_PATIKSLINTA;
  }
  if (distributed) {
    mask |= BUSENA_PASKIRSTYTA;
  }
  if (rejected) {
    mask |= BUSENA_ATMESTA;
  }
  if (returnToAnalytic) {
    mask |= BUSENA_GRAZINTA;
  }
  if (postponed) {
    mask |= BUSENA_ATIDETA;
  }
  if (won) {
    mask |= BUSENA_LAIMETA;
  }
  if (testing) {
    mask |= BUSENA_TESTAVIMAS;
  }
  return mask;
}