import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TplService } from "../../../../taskboard/services/tpl.service";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectsBackend } from "../../../../backend/projects.backend";
import swal from 'sweetalert2';
import { SelectChildTaskModalComponent } from "../../select-child-task-modal/select-child-task-modal.component";

@Component({
  selector: 'select-project-modal',
  templateUrl: 'select-project-modal.component.html',
  styleUrls: ['./select-project-modal.component.scss']
})
export class SelectProjectModalComponent implements OnInit, OnDestroy {

  rows: any[];
  subs: Subscription[] = [];

  isDisabled = false;
  isAdding = false;
  added = false;
  isLoading = true;
  limit = 10;
  count = 0;

  page = 0;
  filter = '';
  sorts = [{ prop: 'code', dir: 'desc' }];
  selected: any = [];

  columns: any;

  constructor(
    public dialogRef: MatDialogRef<SelectProjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private projectsBackend: ProjectsBackend,
    private tplService: TplService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.columns = [
      { name: 'Kodas', prop: 'code', width: 190, sortable: true },
      { name: 'Klientas', prop: 'partner', width: 260, sortable: true },
      { name: 'Pavadinimas', prop: 'name', width: 300, sortable: true },
      { name: 'Etapas', prop: 'stageName', width: 120, sortable: false },
      // { name: 'Etapas', prop: 'stageName', width: 160, sortable: true },
      // { name: 'Projektas iki', prop: 'endDate', width: 118, sortable: true, cellTemplate: this.tplService.projectEndDateTpl },
      // { name: 'Projektas nuo', prop: 'startDate', width: 230, sortable: true, cellTemplate: this.tplService.startDateTpl },
      // { name: 'Įvedė', prop: 'owner', width: 100, sortable: true },
      // { name: 'Prioritetas', prop: 'priorityAbbr', width: 110, cellTemplate: this.tplService.priorityTpl, sortable: true },
      // { name: 'Vertė', prop: 'description', width: 400, sortable: true }
    ];
    this.getProjects();
  }

  getProjects() {
    this.isLoading = true;
    const pars: any = {};
    pars.recordsPerPage = this.limit;
    pars.page = this.page;
    pars.search = this.filter;
    pars.orderBy = this.sorts[0].prop;
    pars.orderDir = this.sorts[0].dir;
    pars.from = '2000-01-01';
    const today = new Date();
    pars.to = '' + today.toLocaleDateString('LT').split(' ')[0];
    // no need to filter "Atlikta" anymore
    // stageBitmask 111 - binary 1101111 filter out "Atlikta" 
    for (let i = 1; i <= 7; i++) {
      // i !== 5 ? pars[i] = true : pars[i] = false;
      pars[i] = true;
    }
    pars.allowAdditionToProject = true;
    pars.partnerId = this.data?.partnerId;
    this.subs.push(this.projectsBackend.getProjectsList(pars).subscribe((resp) => {
      this.isLoading = false;
      this.rows = resp.entities.map(el => ({ ...el, id: el.taskId }));
      this.count = resp.recordsCount || 0;
    }))
  }

  // rowClicked(row) {
  //   console.log('clicked', row);
  //   this.selectedRow = row;

  // }

  search(val) {
    this.filter = val;
    this.getProjects();
  }

  onSelect({ selected }) {
    // console.log('selected', selected);
  }

  onSort(event) {
    this.sorts = event.sorts;
    this.getProjects();
  }

  pageChanged(event) {
    this.page = event.offset;
    this.getProjects();
  }

  protected cancel() {
    this.dialogRef.close();
  }

  addToProject() {
    this.isAdding = true;
    this.subs.push(this.dialog.open(SelectChildTaskModalComponent, { data: { taskId: this.selected[0].taskId }, maxHeight: '90vh', minWidth: '700px' }).afterClosed().subscribe((res) => {
      if (res && res.id) {
        this.subs.push(this.projectsBackend.addTaskToProjectChild(this.data.id, res.id).subscribe((resp) => {
          this.isAdding = false;
          this.added = true;
          swal.fire({
            title: "Užklausa sėkmingai pridėta prie projekto",
            timer: 2000,
            showConfirmButton: false,
            icon: 'success'
          });
          this.dialogRef.close(true);
        }, (err) => {
          this.isAdding = false;
          swal.fire({ title: "Užklausą pridėti prie projekto nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' });
        }));
      } else {
        this.isAdding = false;
      }
    }));
    // this.subs.push(this.projectsBackend.addTaskToProject(this.data.id, this.selected[0].projectId).subscribe((resp) => {
    //   this.isAdding = false;
    //   this.added = true;
    //   swal.fire({
    //     title: "Užklausa sėkmingai pridėta prie projekto",
    //     timer: 2000,
    //     showConfirmButton: false,
    //     icon: 'success'
    //   });
    //   this.dialogRef.close(true);
    // }, (err) => {
    //   this.isAdding = false;
    //   swal.fire({ title: "Užklausą pridėti prie projekto nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' });
    // }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
