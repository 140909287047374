import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { map } from 'rxjs/operators';

import { NewsBackend } from '../../backend/news.backend';
import { Subscription } from 'rxjs';
import { SHORT_DATE } from '../../core/time';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsViewComponent implements OnInit, OnDestroy, AfterViewInit {

  faCalendarAlt = faCalendarAlt;
  newsList = [];
  private _subscriptions: Array<Subscription> = [];
  shortDate = SHORT_DATE;
  panelOpenState = false;

  recordsPerPage = 5;
  currentPage = 0;
  currentPageView = 1;
  recordsCount = 0;
  dataReady = false;

  private newsSubs: Subscription;

  constructor(private newsBackend: NewsBackend, private chg: ChangeDetectorRef, private router: Router) {
  }

  ngOnInit() {
    this.getNews();
  }

  getNews() {
    if (this.newsSubs) {
      this.newsSubs.unsubscribe();
    }
    this.newsSubs = this.newsBackend.getNewsList({
      page: this.currentPage,
      recordsPerPage: this.recordsPerPage,
      orderId: 75, // order by showFrom
      orderUpId: 1,
      showPublished: true,
      startFrom: this.currentPage * this.recordsPerPage,
    }).pipe(map((resp: any) => {
      return {
        data: resp.entities?.map((item) => { return { ...item, shortTextPlain: item.shortText.replace(/<[^>]*>/g, '') } }),
        count: resp.recordsCount || 0,
      }
    })).subscribe((response) => {
      this.newsList = response.data;
      this.recordsCount = response.count;
      this.dataReady = true;
      try {
        this.chg.detectChanges();
      } catch (err) { }
    });
  }

  pageChanged(page) {
    this.currentPage = page.page - 1;
    this.currentPageView = page.page;
    this.getNews();
  }

  goToNews(id) {
    this.router.navigate(['/news/' + id]);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


}

