import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { LIST } from '../../../services/lists/lists.data.service';
import { EventEmitter } from '@angular/core';
import { PartnersBackend } from '../../../backend/partners.backend';
import { Router } from '@angular/router';
import swal from 'sweetalert2';


@Component({
  selector: 'delegate-form',
  templateUrl: './delegate-form.component.html',
  styleUrls: ['./delegate-form.component.scss']
})

export class DelegateFormComponent implements OnInit {

  newDelegateForm: UntypedFormGroup;
  protected isInserting = false;
  protected isCompleted = false;
  protected _partners = LIST.PARTNERS;
  _selectedPartnerId: number;
  protected initialSelector: Function;


  @Input() showButtons = true;
  @Input() delegateInfo = { name: '', lastname: '', email: '', position: '', mobilenr: '', phonenr: '', partnerId: '' };
  @Input() editing = false;
  @Input() showPartnerInput = true;
  @Input() showEmailInput = true;
  @Input() delegateId;
  @Output() disableEdit: EventEmitter<any> = new EventEmitter();

  public value;
  constructor(private formBuilder: UntypedFormBuilder,
    private _location: Location,
    private _partnersBackend: PartnersBackend,
    private router: Router,
    private _chg: ChangeDetectorRef,) {

    // this.initialSelector = (p) => { return this.delegateInfo.partnerId == p.id; };
  }

  ngOnInit() {
    this.buildForm();
  }

  insertDelegate(form) {
    this.isInserting = false;
    this.isCompleted = false;

    if (form.valid) {
      this.isInserting = true;
      const updateObj: any = {};
      updateObj.position = form.value.position;
      updateObj.email = form.value.email;
      updateObj.name = form.value.name;
      updateObj.lastName = form.value.lastname;
      updateObj.mobileNr = form.value.mobilenr;
      // updateObj.partnerId = form.value.partnerId;
      updateObj.phoneNr = form.value.phonenr;
      if (!this.delegateId) {
        updateObj.partnerId = form.value.partnerId;
        this._partnersBackend.insertPartnerDelegate(updateObj).subscribe((val) => {
          this.isCompleted = true;
          swal.fire({
            title: 'Partnerio atstovas sukurtas sėkmingai',
            timer: 2000,
            showConfirmButton: false,
            icon: 'success'
          });
          this.router.navigate(['/admin/delegates']);

        });
      } else {
        this._partnersBackend.updatePartnerDelegate(this.delegateId, updateObj).subscribe((val) => {
          this.isCompleted = true;
          this.disableEdit.emit(true);
        });
      }
    }

  }


  partnerSelected(partnerId) {
    this._selectedPartnerId = partnerId;
  }

  buildForm() {
    this.newDelegateForm = this.formBuilder.group({
      name: [this.delegateInfo.name, Validators.required],
      lastname: [this.delegateInfo.lastname, Validators.required],
      position: [this.delegateInfo.position],
      email: [this.delegateInfo.email, Validators.required],
      mobilenr: [this.delegateInfo.mobilenr, Validators.required],
      phonenr: [this.delegateInfo.phonenr],
      partnerId: [this.delegateInfo.partnerId, !this.delegateId ? Validators.required : null]
    });
    this.value = this.newDelegateForm.value;
  }

  goBack() {
    if (this.delegateId) {
      this.disableEdit.emit(true);
    } else {
      this.router.navigate(['/admin/delegates']);
    }
  }
}
