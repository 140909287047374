
import { map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core/api/api.service";
import { Person } from "../models/persons";

@Injectable()
export class PartnersBackend {
  constructor(private _api: ApiService) {

  }

  public getPartnerDebts(id: any): Observable<any> {
    return this._api.getAuth(`partners/${id}/debts`).pipe(map(
      (r: any) => r && r.body && r.body.entities ? r.body.entities : []),
      map((pd) => {
        if (!pd) {
          return [];
        }
        return (<Array<any>>pd)
      }));
  }

  public getPartnerSales(id: any): Observable<any> {
    return this._api.getAuth(`partners/${id}/salesInfo`).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : []));
  }

  public getPartnerLicences(id: any): Observable<any> {
    return this._api.getAuth(`partners/${id}/licences`).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : [])
    )
  }

  public getPartnerMaintenance(params: any): Observable<any> {
    return this._api.getAuth(`partners/${params.id}/maintenanceInfo`, { onlyRequests: params.onlyRequests }).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : []))
  }

  public getPartnerDelegates(params?: { taskId?: any, partnerId?: any }) {
    return this._api.getAuth('partners/delegates/byTaskOrPartner', { taskId: params.taskId, partnerId: params.partnerId }).pipe(map(
      (r: any) => r.results && r.results.getPartnerDelegates2 && r.results.getPartnerDelegates2.results ? r.results.getPartnerDelegates2.results : []));
  }

  public getPartnerDelegatesByTask(taskId: any) {
    return this._api.getAuth(`partners/delegates/task/${taskId}`).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : []));
  }

  public getPartnerDelegatesByPartner(partnerId: any) {
    return this._api.getAuth(`partners/${partnerId}/delegates`).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : []));
  }

  public getRequestProductsByPartner(partnerId: any) {
    // return this._api.getAuth(`partners/${partnerId}/products`).pipe(map( //TODO needRequest
    return this._api.getAuth(`lists/products/${partnerId}`).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : []));
  }

  public getCompanyEmployeesByTask(taskId: any) {
    return this._api.getAuth(`partners/task/${taskId}/employees`).pipe(map(
      (r: any) => r.body && r.body.entities ? r.body.entities : []))
  }

  public getPartnerEmployees(partnerId: number): Observable<Person[]> {
    return <any>this._api.getAuth(`partners/${partnerId}/employees`).pipe(map((el: any) => el.body.entities));
  }

  public getPartnerInfo(partnerId) {
    return this._api.getAuth(`partners/${partnerId}/info`).pipe(map((el: any) => el.body));
  }

  public getPartnerNote(partnerId) {
    return this._api.getAuth(`partners/${partnerId}/notes`).pipe(map((el: any) => el.body));
  }

  public updatePartnerNote(updateObj) {
    return this._api.postAuth(`partners/notes`, updateObj).pipe(map((el: any) => el.body));
  }

  public getAllPartnerDelegates(partnerId) {
    return this._api.getAuth(`partners/${partnerId}/allDelegates`).pipe(map((el: any) => el.body.entities));
  }

  public insertPartnerDelegate(partnerDelegate) {
    return this._api.postAuth('partners/delegate', partnerDelegate);
  }

  public updatePartnerDelegate(id, partnerDelegate) {
    return this._api.postAuth(`partners/delegate/${id}`, partnerDelegate);
  }

  public updatePartnersDelegateList(id, partnerDelegatesList) {
    return this._api.postAuth(`partners/${id}/updateDelegatesList`, partnerDelegatesList);
  }

  public updatePartnersDefaultDelegate(id, defaultDelegate) {
    return this._api.postAuth(`partners/${id}/updateDefaultDelegate`, defaultDelegate);
  }

  public getDelegateInfo(id) {
    return this._api.getAuth(`partners/delegate/${id}`).pipe(map(x => x.body));
  }

  public getPartners(): Observable<Person[]> {
    return <any>this._api.getAuth('partners').pipe(map((el: any) => el.body.entities));
  }

  public getPartnersList(pars): Observable<Person[]> {
    return <any>this._api.getAuth('admin/partners', pars).pipe(map((res: any) => res.body));
  }

  public getPartnerProducts(id): Observable<Person[]> {
    return <any>this._api.getAuth(`partners/${id}/products`).pipe(map((el: any) => el.body.entities));
  }
}
