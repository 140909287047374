
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/api/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class NewsBackend {
  constructor(private _api: ApiService) {
  }

  getNewsList(params): Observable<any[]> {
    return <any>this._api.getAuth('news/list', params).pipe(map((res: any) => res.body));
  }

  getNews(newsId) {
    return <any>this._api.getAuth(`news/${newsId}`, null).pipe(map((res: any) => res.body));
  }

  editNews(news) {
    return <any>this._api.postAuth('news/update', news).pipe(map((res: any) => res.body));
  }

  createNews(news) {
    return <any>this._api.postAuth('news/create', news).pipe(map((res: any) => res.body));
  }

  addFiles(filesObj): any {
    return <any>this._api.postAuth('news/addFile', filesObj).pipe(map((res: any) => res.body));
  }

  getFiles(newsId): any {
    return <any>this._api.getAuth('news/file/' + newsId, null).pipe(map((res: any) => res.body));
  }

}