
import { empty as observableEmpty, Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AsNumber } from "../../core/decorators/type-checking";
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Timer } from "../../template/shared/services/timer/timer";
import { JobsListEntry } from "./jobs-list-entry";
import { JobModel } from "./job-model";
import { getPrice, parseTime, rangeTime, setTimeToDate, timeToFloat, timeToString } from "../../core/time/helpers";
import { JobsBackend } from "../../backend/jobs/jobs.backend";
import { dateToString } from "../common/functions";
import { FinishJob } from "../../backend/jobs/models";
import { TaskComment, TaskReview } from "./task-review";
import { CommentTask } from "../../backend/tasks.backend";
import { TaskboardDataService } from "../services/taskboard-data.service";

// new job = 0,1,2,3 - edit job - 4, disabled edit(view) - 5

export enum JobFormMode {
  TASK_JOB,
  FREE_JOB,
  COMMENT_JOB,
  TIMER_JOB,
  EDIT_JOB,
  REVIEW_JOB,
  // REVIEW_NOT_OWNED
}

const EMPTY_TIME = '00:00';

export class JobFormModel {

  public get isValid() {
    const current = this._formGroup.value;
    current.serviceId = parseInt(current.serviceId);
    return this._formGroup.valid;
  }

  private _initialValue: Object;

  // private _formGroup: UntypedFormGroup;
  private _formGroup: FormGroup;

  private _timeFrom: string;
  public set timeFrom(val: string) {
    this._setTime(val, 'timeFrom');
  }

  public get timeFrom(): string {
    return this._timeFrom || this.job.timeFrom || EMPTY_TIME;
  }

  private _userId;
  public set userId(val: number) {
    this._userId = val;
  }

  public get userId(): number {
    return this._userId || null;
  }

  private _timeTo: string;
  public set timeTo(val: string) {
    this._setTime(val, 'timeTo');
  }

  public get timeTo(): string {
    return this._timeTo || this.job.timeTo || EMPTY_TIME;
  }

  private _durationView: string;
  public set durationView(val: string) {
    this._durationView = val;
    if (this._formGroup) {
      this._formGroup.patchValue({ durationView: val });
    }
  }

  public get durationView(): string {
    // return timeToString(rangeTime(this.timeFrom, this.timeTo)) || EMPTY_TIME;
    return this._durationView || EMPTY_TIME;
  }

  private _removeParticipant: boolean;
  public get removeParticipant(): boolean {
    return this._removeParticipant;
  }
  public set removeParticipant(val: boolean) {
    this._removeParticipant = val;
  }

  private _visibleSum: boolean;
  public set visibleSum(val: boolean) {
    this._visibleSum = val;
    // if (!this._visibleSum && this.formMode !== JobFormMode.REVIEW_JOB && this.formMode !== JobFormMode.REVIEW_NOT_OWNED) {
    if (!this._visibleSum && this.formMode !== JobFormMode.REVIEW_JOB) {
      if (this._formGroup) {
        this._formGroup.controls.sumNoVat.disable();
        this._formGroup.controls.hourlyRate.enable();
      }
      this._calcSumNoVat();
      // } else if (this.formMode !== JobFormMode.REVIEW_JOB && this.formMode !== JobFormMode.REVIEW_NOT_OWNED) {
    } else if (this.formMode !== JobFormMode.REVIEW_JOB) {
      if (this._formGroup) {
        this._formGroup.controls.sumNoVat.enable();
        this._formGroup.controls.hourlyRate.disable();
      }
    } else {
      if (this._formGroup) {
        this._formGroup.controls.sumNoVat.disable();
        this._formGroup.controls.hourlyRate.disable();
      }
    }
  }
  public get visibleSum(): boolean {
    return typeof this._visibleSum == 'boolean' ? this._visibleSum : this.job.visibleSum;
  }

  private _duration: string;
  public set duration(val: string) {
    this._duration = val;
    if (this._formGroup) {
      this._formGroup.controls.duration.setValue(val);
    }
    if (!this._visibleSum) {
      this._calcSumNoVat();
    }
  }

  public get duration(): string {
    return this._duration || this.job.duration || EMPTY_TIME;
  }

  private _dateObj;
  public set dateObj(val: Date) {
    this._dateObj = val;
  }

  public get dateObj(): Date {
    return this._dateObj || new Date(this.job.date);
  }

  public get taskId(): number {
    return this.job.taskId || this.extras.taskId;
  }

  public get description(): string {
    return this.job.description;
  }

  public get originalDescription(): string {
    return this.job.originalDescription;
  }

  public set originalDescription(val: any) {
    if (this._formGroup) {
      this._formGroup.controls.originalDescription.setValue(val);
    }
  }

  private _travelPrice: number;
  public set travelPrice(val: number) {
    this._travelPrice = val;
  }

  public get travelPrice(): number {
    return this._travelPrice || this.job.travelPrice;
  }

  public get partnerDisabled(): boolean {
    return !!this.taskId;
  }

  public get productDisabled(): boolean {
    return !!this.taskId;
  }

  public get productId(): number {
    return (<JobModel>this.job).productId;
  }

  public get date(): string {
    return this.job.date;
  }

  private _sumNoVat: number;
  @AsNumber({ float: true })
  public set sumNoVat(val: number) {
    this._sumNoVat = val;
  }

  public get sumNoVat(): number {
    return (this._sumNoVat && !this._visibleSum) ? this._sumNoVat : this.job.sumNoVat;
  }

  public get partnerId(): number {
    return this.job.partnerId || this.extras.partnerId;
  }

  private _firstHourlyRateSet = true;
  private _hourlyRate: number;
  @AsNumber({ float: true })
  public set hourlyRate(val: number) {
    this._hourlyRate = val;
    if (this._firstHourlyRateSet && this.formMode == JobFormMode.EDIT_JOB) {
      this._firstHourlyRateSet = false;
      return;
    }
    if (!this._visibleSum) {
      this._calcSumNoVat();
    }
  }

  public get hourlyRate(): number {
    return this._hourlyRate;
  }

  private _firstServiceSet = true;
  private _serviceId: number;
  public set serviceId(val: number) {
    this._serviceId = val;
    if (this._firstServiceSet && this.formMode == JobFormMode.EDIT_JOB) {
      this._firstServiceSet = false;
      return;
    }
    if (!this._visibleSum) {
      this._calcSumNoVat();
    }
  }

  public get serviceId(): number {
    return this.job.serviceId;
  }

  public get addToDpa(): boolean {
    return !!this.job.dpaId;
  }

  private _firstIsPaidSet = true;
  private _isPaid: boolean;
  public set isPaid(val: boolean) {
    this._isPaid = val;
    if (this._firstIsPaidSet && this.formMode == JobFormMode.EDIT_JOB) {
      this._firstIsPaidSet = false;
      return;
    }
    if (!this._visibleSum) {
      this._calcSumNoVat();
    }
  }

  public get isPaid(): boolean {
    return typeof this._isPaid == 'boolean' ? this._isPaid : this.job.isPaid;
  }

  public get filesAllowed(): boolean {
    return this.formMode === JobFormMode.FREE_JOB
      || this.formMode === JobFormMode.TASK_JOB
      || this.formMode === JobFormMode.TIMER_JOB
      || this.formMode === JobFormMode.EDIT_JOB
      || this.formMode === JobFormMode.COMMENT_JOB
      || this.formMode === JobFormMode.REVIEW_JOB
    // || this.formMode === JobFormMode.REVIEW_NOT_OWNED;
  }

  public get disallowFilesEdit(): boolean {
    return this.formMode !== JobFormMode.FREE_JOB
      && this.formMode !== JobFormMode.TASK_JOB
      && this.formMode !== JobFormMode.TIMER_JOB
      && this.formMode !== JobFormMode.COMMENT_JOB
      && this.formMode !== JobFormMode.EDIT_JOB;
  }

  public filesKey: string;

  public get durationNotZero(): boolean {
    return true;
  }

  private _setTime(val, what: 'timeTo' | 'timeFrom' | 'duration') {
    this['_' + what] = val;
    if (this._formGroup) {
      this._formGroup.patchValue({ [what]: val });
      // this._setDuration();
    }
  }

  private get _preparedFormValue(): object {
    let fv = { ...this._formGroup.value };
    fv.timeFrom = dateToString(setTimeToDate(fv.date, this.timeFrom));
    fv.timeTo = dateToString(setTimeToDate(fv.date, this.timeTo));
    delete fv.durationView;
    delete fv.date;
    fv.removeParticipant = fv.removeParticipant ? 1 : 0;
    fv.paidTimeMin = +parseTime(this.duration)?.hours * 60 + +parseTime(this.duration)?.minutes;
    fv.duration = timeToFloat(this.duration);
    if (fv.sumNoVat == undefined || fv.sumNoVat == null) {
      fv.sumNoVat = this.sumNoVat;
    }
    if (fv.isPaid) {
      fv.isPaid = 1;
    } else if (fv.isPaid === false) {
      fv.isPaid = 0;
    }
    if (!this.isPaid) {
      fv.sumNoVat = 0;
    }
    if (this.hourlyRate) {
      fv.hourlyRate = this.hourlyRate;
    }
    if (this.formMode == JobFormMode.TASK_JOB || this.formMode == JobFormMode.COMMENT_JOB || this.formMode == JobFormMode.TIMER_JOB) {
      fv.taskId = this.job.taskId;
    }
    if (this.extras) {
      fv = { ...fv, ...this.extras };
    }
    if (this.filesAllowed && this.filesKey) {
      fv['filesKey'] = this.filesKey;
    }
    return fv;
  }

  private _setDuration() {
    this._durationView = timeToString(rangeTime(this.timeFrom, this.timeTo));
  }

  public get submitFn(): (jb: JobsBackend) => Observable<any> {
    switch (this.formMode) {
      case JobFormMode.COMMENT_JOB:
      case JobFormMode.TASK_JOB:
      case JobFormMode.FREE_JOB:
        // return (jb) => jb.finishJob(<FinishJob>{ ...this._preparedFormValue, isInternalComment: ((<any>this._preparedFormValue) && (<any>this._preparedFormValue).comment) ? (<any>this._preparedFormValue).comment.isInternalComment : undefined });
        return (jb) => jb.finishJob(<FinishJob>{ ...this._preparedFormValue });
      case JobFormMode.TIMER_JOB:
        return (jb) => {
          return jb.finishJob(<FinishJob>this._preparedFormValue).pipe(tap((data) => {
            // if ((<any>this._preparedFormValue).timerId && data && data.entityId) {
            //   this.taskboardDataService.jobCreatedFromTimer.emit({ timerId: (<any>this._preparedFormValue).timerId, jobId: data.entityId });
            // }
            if ((<any>this._preparedFormValue).timerId && data && data?.entity?.job?.id) {
              this.taskboardDataService.jobCreatedFromTimer.emit({ timerId: (<any>this._preparedFormValue).timerId, jobId: data.entity.job.id });
            }
          }));
        };
      case JobFormMode.EDIT_JOB:
        return (jb) => jb.editJob(<FinishJob>this._preparedFormValue, this.job.id);
    }
    return (jb) => observableEmpty();
  }

  public get submitButtonText(): string {
    switch (this.formMode) {
      default: return 'IŠSAUGOTI';
    }
  }

  constructor(public readonly formMode: JobFormMode, public taskboardDataService: TaskboardDataService, public readonly job?: JobsListEntry, public extras?: any) {

    if (!job) {
      this.job = <any>{};
    }
    if (!extras) {
      this.extras = {};
    }
    if (formMode == JobFormMode.EDIT_JOB) {
      this._firstCalc = true;
    }
  }

  public static newTaskJob(taskboardDataService: TaskboardDataService, task: TaskReview): JobFormModel {
    return new JobFormModel(JobFormMode.TASK_JOB, taskboardDataService, <any>{ ...task, taskId: task.id }, { isCrmUserDelegate: task.crmUserIsDelegate });
  }

  public static newCommentJob(taskboardDataService: TaskboardDataService, comment: TaskComment, task: TaskReview): JobFormModel {
    return new JobFormModel(JobFormMode.COMMENT_JOB, taskboardDataService, <any>{ ...task, description: comment.text, taskId: task.id }, { taskId: task.id, isCrmUserDelegate: task.crmUserIsDelegate });
  }

  public static newJobWithComment(taskboardDataService: TaskboardDataService, task: TaskReview, commentTask: CommentTask): JobFormModel {
    return new JobFormModel(JobFormMode.COMMENT_JOB, taskboardDataService, <any>{ ...task, description: commentTask.text, taskId: task.id }, { comment: commentTask, isCrmUserDelegate: task.crmUserIsDelegate });
  }

  public static fromJobRow(taskboardDataService: TaskboardDataService, job: JobsListEntry): JobFormModel {
    return new JobFormModel(JobFormMode.EDIT_JOB, taskboardDataService, job);
  }

  public static newFreeJob(taskboardDataService: TaskboardDataService, partnerId?: number): JobFormModel {
    return new JobFormModel(JobFormMode.FREE_JOB, taskboardDataService, <any>{ partnerId: partnerId, date: new Date() });
  }

  public static newTimerJob(taskboardDataService: TaskboardDataService, timer: Timer, task: TaskReview) {
    const currentTime = new Date();
    const fromTime = new Date(currentTime.getTime() - (<any>timer).allTime);
    return new JobFormModel(JobFormMode.TIMER_JOB, taskboardDataService, <any>{ taskTypeId: task.taskTypeId, serviceId: task.serviceId, partnerId: task.partnerId, taskId: task.id, description: timer.title, timeFrom: timeToString(parseTime(fromTime)), timeTo: timeToString(parseTime(currentTime)) }, { timerId: timer.id, isCrmUserDelegate: task.crmUserIsDelegate });
  }

  public static editJob(taskboardDataService: TaskboardDataService, job: JobsListEntry): JobFormModel {
    return new JobFormModel(JobFormMode.EDIT_JOB, taskboardDataService, job);
  }

  public static reviewJob(taskboardDataService: TaskboardDataService, job: JobsListEntry): JobFormModel {
    return new JobFormModel(JobFormMode.REVIEW_JOB, taskboardDataService, job);
  }

  // public static reviewJobNotOwned(taskboardDataService: TaskboardDataService, job: JobsListEntry): JobFormModel {
  //   return new JobFormModel(JobFormMode.REVIEW_NOT_OWNED, taskboardDataService, job);
  // }

  /**
   * Determines if job form should be refreshed after last closing
   * @param formModel - Old form model
   */
  public refreshForm(formModel: JobFormModel): boolean {
    return false;
  }
  // public buildForm(fb: UntypedFormBuilder): UntypedFormGroup
  public buildForm(fb: FormBuilder): FormGroup {
    // const isJobReview = this.formMode == JobFormMode.REVIEW_JOB || this.formMode == JobFormMode.REVIEW_NOT_OWNED;
    const isJobReview = this.formMode == JobFormMode.REVIEW_JOB;
    this._prepare();
    const isNew = this.formMode == JobFormMode.TASK_JOB || this.formMode == JobFormMode.FREE_JOB || this.formMode == JobFormMode.COMMENT_JOB || this.formMode == JobFormMode.TIMER_JOB;

    this._formGroup = fb.group({
      isPaid: [{ value: this.isPaid, disabled: isJobReview }],
      userId: [{ value: this.userId, disabled: isJobReview }, Validators.required],
      date: [{ value: this.dateObj, disabled: isJobReview }],
      partnerId: [{ value: this.partnerId, disabled: isJobReview || this.partnerDisabled }, Validators.required],
      productId: [{ value: this.productId, disabled: isJobReview || this.productDisabled }],
      serviceId: [{ value: this.serviceId, disabled: isJobReview }, Validators.required],
      hourlyRate: [{ value: this.hourlyRate, disabled: isJobReview }],
      travelPrice: [{ value: this.travelPrice, disabled: isJobReview }],
      removeParticipant: [{ value: this.removeParticipant, disabled: isJobReview }],
      sumNoVat: [{ value: this.sumNoVat, disabled: isJobReview }],
      description: [{ value: this.description, disabled: isJobReview }, Validators.required],
      originalDescription: [{ value: this.originalDescription, disabled: isJobReview }],
      timeFrom: [{ value: this.timeFrom, disabled: isJobReview }],
      timeTo: [{ value: this.timeTo, disabled: isJobReview }],
      durationView: [{ value: this.durationView, disabled: isJobReview }],
      duration: [{ value: this.duration, disabled: isJobReview }],
      visibleSum: [{ value: this.visibleSum, disabled: isJobReview }]
    });
    this._initialValue = this._formGroup.value;
    return this._formGroup;
  }

  private _prepare() {
    switch (this.formMode) {
      case JobFormMode.TASK_JOB:
      case JobFormMode.FREE_JOB:
      case JobFormMode.COMMENT_JOB:
        this.dateObj = new Date((new Date()).getTime() - (60 * 1000 * 10));
        const tFrom = this.dateObj;
        const tTo = new Date();
        this.timeFrom = timeToString(parseTime(tFrom));
        this.timeTo = timeToString(parseTime(tTo));
        this._setDuration();
        break;
      case JobFormMode.TIMER_JOB:
        this.duration = this.job.duration;
        this.dateObj = new Date();
        break;
      case JobFormMode.EDIT_JOB:
      case JobFormMode.REVIEW_JOB:
        // case JobFormMode.REVIEW_NOT_OWNED:
        this.hourlyRate = this.job.hourlyRate;
    }
  }

  private _firstCalc = false;
  private _calcSumNoVat() {
    if (!this.isPaid) {
      return;
    }
    if (this._firstCalc) {
      this._firstCalc = false;
      return;
    }

    if (this._formGroup) {
      this.sumNoVat = <number>getPrice(this.duration, this.hourlyRate);
      this._formGroup.controls.sumNoVat.setValue(this.sumNoVat);
    }
  }
}
