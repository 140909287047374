
import { contains, containsAsciiFold, Filter, startsWith } from '../../template/shared/proto/components/input/protoInput.component';
import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs'
import { TasksBackend } from '../../backend/tasks.backend';
import { PersonsBackend } from '../../backend/persons.backend';
import { ListsBackend } from '../../backend/lists.backend';
import { UserBackend } from '../../backend/user.backend';
import { MarketingCampaignBackend } from '../../backend/marketing-campaign.backend';
import { SalesBackend } from '../../backend/sales.backend';

type LoadList<T> = (p?: any, txtStream?: Observable<string>) => Observable<Array<T>>

export type List<T> = {
  type: LIST,
  mapView: (t: T) => string,
  mapVal: (t: T) => any,
  placeholder?: string,
  initialValueSelector?: (v: any) => boolean,
  load: LoadList<T>,
  searchable?: boolean,
  filterFn?: Filter
};

type MapVal = (t: any) => any
type MapView = (t: any) => string

export const enum LIST {
  PARTNERS = 'getPartnersFast',
  ALL_DELEGATES = 'getDelegatesList',
  PRODUCTS = 'getProducts',
  PRIORITIES = 'getTaskPriorities',
  SERVICES = 'getServices',
  SERVICES_ALL = 'getServicesAll',
  PROJECTS = 'getProjects',
  MAIN_TASKS = 'getMainTasks',
  PERSONS = 'getPersonsList',
  CRM_PERSONS = 'getCrmPersons',
  GROUPS = 'getGroups',
  TASK_TYPES = 'getTaskTypes',
  PARTNER_DELEGATES = 'getPartnerDelegates', //Naudojama tik admin daly
  EMPLOYEES = 'getCompanyEmployees',
  TASK_TYPES_FILTER = 'getTaskTypesFilter',
  REQUEST_TYPES_FILTER = 'getRequestTypesFilter',
  REQUEST_TYPES = 'getRequestTypes',
  REQUEST_TYPES_MARKETING = 'getRequestTypesMarketing',
  EMPLOYEES_ALT = 'getEmployeesAlt',
  MARKETING_SOURCES_LIST = 'getMarketingSourcesList',
  MARKETING_CLIENT_TYPES_LIST = 'getMarketingClientTypesList',
  SALES_PRODUCTS_LIST = 'getSalesProducts',
  SALES_TYPES_LIST = 'getSalesTypes',
  SALES_CLIENTS_NEEDS_LIST = 'getClientNeeds',
  MARKETING_CAMPAIGNS_LIST = 'getMarketingCampaigns',
  USERS_LIST = 'getUsers',
  STREAMS_LIST = 'getStreams',
}

const view: MapView = (a: any) => a && a.name;
const val: MapVal = (a: any) => a && a.id;

type ParamFilter = (p: any) => boolean

type ListOptions = {
  list: LIST,
  mapView?: MapView,
  mapVal?: MapVal,
  paramFilter?: ParamFilter,
  placeholder?: string,
  filterFn?: Filter
  searchFn?: (txt: string, o: any) => boolean,
  initialValueSelector?: (v: any) => boolean,
  loaded?: boolean,
  singleLoad?: boolean,
  getter?: 'query' | 'http'
}

@Injectable()
export class ListsDataService {

  private _lists: Array<ListOptions> = [
    {
      list: LIST.PARTNERS,
      placeholder: 'Pasirinkite partnerį',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.id,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.ALL_DELEGATES,
      placeholder: 'Pasirinkite atstovą',
      mapView: (a: any) => a.name + ' ' + a.lastName,
      mapVal: (a: any) => a,
      filterFn: containsAsciiFold,
      singleLoad: true
    },
    // {
    //   list: LIST.PRODUCTS,
    //   placeholder: 'Pasirinkite produktą',
    //   filterFn: contains,
    //   singleLoad: true
    // },
    {
      list: LIST.PRIORITIES,
      placeholder: 'Pasirinkite prioritetą',
      initialValueSelector: (v) => v.priority == 60,
      singleLoad: true
    },
    {
      list: LIST.TASK_TYPES_FILTER,
      singleLoad: true,
      placeholder: 'Pasirinkite veiklos tipą'
    },
    {
      list: LIST.REQUEST_TYPES_FILTER,
      singleLoad: true,
      placeholder: 'Pasirinkite veiklos tipą'
    },
    {
      list: LIST.SERVICES,
      placeholder: 'Pasirinkite paslaugą',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.serviceId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.SERVICES_ALL,
      placeholder: 'Pasirinkite paslaugą',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.serviceId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.PROJECTS,
      placeholder: 'Pasirinkite projektą'
    },
    {
      list: LIST.MAIN_TASKS,
      placeholder: 'Pasirinkite pagrindinę užduotį'
    },
    {
      list: LIST.PERSONS,
      filterFn: contains
    },
    {
      list: LIST.CRM_PERSONS,
      mapView: (a: any) => a.name + ' ' + a.lastName,
      mapVal: (a: any) => a.personId,
      filterFn: contains
    },
    { list: LIST.GROUPS },
    {
      list: LIST.TASK_TYPES,
      placeholder: 'Pasirinkite užduoties tipą',
    },
    {
      list: LIST.REQUEST_TYPES,
      placeholder: 'Pasirinkite užduoties tipą',
    },
    {
      list: LIST.REQUEST_TYPES_MARKETING,
      placeholder: 'Pasirinkite užduoties tipą',
    },
    {
      list: LIST.PARTNER_DELEGATES,
      placeholder: 'Pasirinkite atstovą',
      // mapView: (a: any) => a.Atst_Vardas + ' ' + a.Atst_Pavarde,
      // mapVal: (a: any) => a.AtstovaiId,
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.id,
      paramFilter: (p) => p != null,
      filterFn: contains
    },
    {
      list: LIST.EMPLOYEES,
      // mapView    : (a: any) => a.Vardas + ' ' + a.Pavarde,
      // mapVal     : (a: any) => a.MusuAsmenysId,
      placeholder: 'Pasirinkite darbuotoją',
      filterFn: contains
    },
    {
      list: LIST.EMPLOYEES_ALT,
      placeholder: 'Pasirinkite vartotoją',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.id,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.MARKETING_SOURCES_LIST,
      placeholder: 'Pasirinkite šaltinį',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.typeId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.MARKETING_CLIENT_TYPES_LIST,
      placeholder: 'Pasirinkite kliento tipą',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.typeId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.SALES_PRODUCTS_LIST,
      placeholder: 'Pasirinkite produktą',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.id,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.SALES_TYPES_LIST,
      placeholder: 'Pasirinkite pardavimo tipą',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.typeId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.SALES_CLIENTS_NEEDS_LIST,
      placeholder: 'Pasirinkite kliento poreikį',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.typeId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.MARKETING_CAMPAIGNS_LIST,
      placeholder: 'Pasirinkite marketingo kampaniją',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.campaignsId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.USERS_LIST,
      placeholder: 'Pasirinkite vartotoją',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.laPersonId,
      filterFn: contains,
      singleLoad: true
    },
    {
      list: LIST.STREAMS_LIST,
      placeholder: 'Pasirinkite srautą',
      mapView: (a: any) => a.name,
      mapVal: (a: any) => a.typeId,
      filterFn: contains,
      singleLoad: true
    },
  ];

  private __listDatas = {};

  constructor(
    private _tasksBackend: TasksBackend,
    private _personsBackend: PersonsBackend,
    private _listsBackend: ListsBackend,
    private _marketingBackend: MarketingCampaignBackend,
    private _salesBackend: SalesBackend,
    private _userBackend: UserBackend) {
  }

  public getList<T>(list: LIST): List<T> {
    let load: LoadList<T>;
    let lo: ListOptions = this._lists.find((l) => l.list === list);
    if (!lo) {
      throw new Error('Unknown list: ' + list);
    }
    load = (p) => {
      if (lo.singleLoad) {
        let pk = 'NO_ARGS';
        try {
          pk = JSON.stringify(p);
        } catch (e) {
        }
        const listDatas = this.__listDatas;
        const key = list + '_' + pk;
        const ld = listDatas[key];
        if (ld) {
          return ld;
        } else {
          const loading = this._makeLoader(list, p);
          listDatas[key] = loading;
          return loading;
        }
      } else {
        return this._makeLoader(list, p);
      }
    }; //TODO: perkelti _isLoading.next(true) i streama

    return ({
      type: list,
      // _isLoading           : _isLoading,
      mapView: lo.mapView ? lo.mapView : view,
      mapVal: lo.mapVal ? lo.mapVal : val,
      load: load,
      placeholder: lo.placeholder,
      searchable: lo.searchFn != null,
      initialValueSelector: lo.initialValueSelector,
      filterFn: lo.filterFn ? lo.filterFn : startsWith
    })
  }

  public loadList(lst: LIST): Observable<Array<any>> {
    const list = this.getList(lst);
    if (list) {
      return list.load();
    }
  }

  private _makeLoader(list: LIST, p) {
    switch (list) {
      case LIST.TASK_TYPES_FILTER:
        return this._listsBackend.getTaskTypes();
      case LIST.REQUEST_TYPES_FILTER:
        return this._listsBackend.getRequestTypes();
      case LIST.EMPLOYEES:
        return this._personsBackend.getEmployees();
      case LIST.PARTNERS:
        return this._listsBackend.getAllPartners();
      case LIST.EMPLOYEES_ALT:
        return this._listsBackend.getAllEmplyees();
      case LIST.REQUEST_TYPES:
        return this._listsBackend.getTaskTypes();
      case LIST.PRIORITIES:
        return this._listsBackend.getTaskPriorities();
      case LIST.SERVICES:
        if (p && p[0] && p[0].taskTypeId) {
          return this._listsBackend.getServices(p[0].taskTypeId);
        } else {
          return [];
        }
      case LIST.SERVICES_ALL:
        return this._listsBackend.getServices(null);
      case LIST.PERSONS:
        return this._listsBackend.getPersons();
      case LIST.CRM_PERSONS:
        return this._listsBackend.getCrmPersons();
      case LIST.GROUPS:
        return this._listsBackend.getGroups();
      case LIST.TASK_TYPES:
        return this._listsBackend.getTaskTypes();
      case LIST.MARKETING_SOURCES_LIST:
        return this._marketingBackend.getMarketingSourcesList();
      case LIST.MARKETING_CLIENT_TYPES_LIST:
        return this._marketingBackend.getMarketingClientTypesList();
      case LIST.SALES_PRODUCTS_LIST:
        return this._listsBackend.getProducts();
      case LIST.SALES_TYPES_LIST:
        return this._salesBackend.getSaleTypes();
      case LIST.SALES_CLIENTS_NEEDS_LIST:
        return this._salesBackend.getSaleClientsNeeds();
      case LIST.MARKETING_CAMPAIGNS_LIST:
        const today = new Date();
        today.setDate(today.getDate() + 356);
        return this._marketingBackend.getMarketingCampaignsList(
          { from: '2016-01-01', to: today.toLocaleDateString('LT'), orderBy: 'name', orderDir: 'asc', pageLength: 999999, startFrom: 0 }).pipe(
            map((res: any) => res.entities)
          );
      case LIST.USERS_LIST:
        return this._personsBackend.getEmployees();
      case LIST.REQUEST_TYPES:
        return this._listsBackend.getRequestTypes();
      case LIST.REQUEST_TYPES_MARKETING:
        return this._listsBackend.getRequestTypesMarketing();
      case LIST.ALL_DELEGATES:
        return this._userBackend.getDelegatesList({ recordPerPage: 99999999, orderId: 1, orderUpId: 1 }).map(resp => resp.entities);
      case LIST.PARTNER_DELEGATES:
        if (p && p[0] && p[0].partnerId) {
          return this._tasksBackend.getRequestPersonsByPartner(p[0].partnerId);
        } else if (p && p[0] && p[0].taskId) {
          return this._tasksBackend.getRequestPersonsByTask(p[0].taskId);
        } else {
          return;
        }
      case LIST.STREAMS_LIST:
        return this._tasksBackend.getStreamsList();
    }
  }
}
