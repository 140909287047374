
import { catchError } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { TaskReview } from "../../../models/task-review";
import { Subscription, throwError } from 'rxjs';
import swal from 'sweetalert2';
import { CalendarCustomHeaderComponent } from '../../../../components/filters/date-interval-alt/calendarCustomHeader.component';
import { ProjectsBackend } from '../../../../backend/projects.backend';

@Component({
  selector: 'update-warranty-date',
  templateUrl: './update-warranty-date.component.html',
  styleUrls: ['./update-warranty-date.component.scss']
})
export class UpdateWarrantyDateComponent implements OnInit, OnDestroy {

  protected _submitting = false;
  protected _submitted = false;
  // componentType: 'deadLine' | 'dateStart' | 'warrantyDate';
  // ingoingFieldName: 'dateDeadline' | 'dateStart' | 'warrantyDate';
  // outgoingFieldName: 'deadline' | 'dateStart' | 'warrantyDate';
  task: TaskReview;
  _deadlineForm: UntypedFormGroup;
  subs: Subscription[] = [];
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;
  placeholder: string = 'Garantijos data';
  isNew: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private tasksBackend: TasksBackend,
    private projectsBackend: ProjectsBackend,
    public dialogRef: MatDialogRef<UpdateWarrantyDateComponent>,
    private _formBuilder: UntypedFormBuilder) {
    this.task = data.task;
    this.isNew = data.isNew;
  }

  ngOnInit() {
    this._buildForm();
  }

  private _buildForm() {
    let initDate;
    if (this.isNew) {
      initDate = new Date();
      initDate.setMonth(initDate.getMonth() + 3);
    } else {
      initDate = this.task.projectDetails.warrantyDate ? new Date(this.task.projectDetails.warrantyDate) : null;
    }
    this._deadlineForm = this._formBuilder.group({
      date: [initDate, Validators.required],
      text: [null, null],
    });
  }

  dateChanged(el) {
    el.close && el.close();
  }

  protected _submit() {
    this._submitting = true;
    const date = new Date(this._deadlineForm.controls.date.value);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    this.subs.push(this.projectsBackend.updateWarrantyDate(this.task.projectDetails.projectId, date)
      .pipe(catchError((err) => {
        console.log('caught error', err);
        return throwError(err);
      }))
      .subscribe((resR: any) => {
        const res = resR.body;
        this._submitting = false;
        this._submitted = true;
        if (res && res.success) {
          this.dialogRef.close({ success: true });
        } else {
          this.dialogRef.close({ success: false, errorMessage: res && res.error });
        }
      }, (err) => {
        this._submitting = false;
        swal.fire({
          title: 'Įvyko klaida',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      }));
  }

  protected _cancel() {
    this.dialogRef.close({ success: null });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
