import { Component, OnInit, AfterViewChecked, OnDestroy, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NEWS_STATUS_PUBLISHED } from '../../../content/constants';
import { NewsBackend } from '../../../backend/news.backend';
import { UploadFileComponent } from '../../../template/shared/components/upload-file/upload-file.component';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CalendarCustomHeaderComponent } from '../../../components/filters/date-interval-alt/calendarCustomHeader.component';


declare var tinymce: any;

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit, AfterViewInit, OnDestroy {

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  _subscriptions: Subscription[] = [];

  private _htmlEditorConfig = {
    // plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    // plugins: 'paste searchreplace autolink save directionality code visualblocks visualchars image link media table charmap hr pagebreak nonbreaking anchor toc lists wordcount textpattern noneditable help charmap emoticons',
    plugins: 'paste searchreplace autolink save directionality code visualblocks visualchars image link media table charmap hr pagebreak nonbreaking anchor lists wordcount textpattern noneditable help charmap emoticons',
    // imagetools_cors_hosts: ['picsum.photos'],
    // menubar: 'file edit view insert format tools table help',
    menubar: 'edit view insert format tools table help',
    // toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | insertfile image media link anchor codesample | ltr rtl',
    toolbar_sticky: true,
    // autosave_ask_before_unload: true,
    // autosave_interval: '30s',
    // autosave_prefix: '{path}{query}-{id}-',
    // autosave_restore_when_empty: false,
    // autosave_retention: '2m',
    // image_advtab: true,
    // content_css: '//www.tiny.cloud/css/codepen.min.css',
    // link_list: [
    //   { title: 'My page 1', value: 'http://www.tinymce.com' },
    //   { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
    // image_list: [
    //   { title: 'My page 1', value: 'http://www.tinymce.com' },
    //   { title: 'My page 2', value: 'http://www.moxiecode.com' }
    // ],
    // image_class_list: [
    //   { title: 'None', value: '' },
    //   { title: 'Some class', value: 'class-name' }
    // ],
    // importcss_append: true,
    // file_picker_callback: function (callback, value, meta) {
    //   /* Provide file and text for the link dialog */
    //   if (meta.filetype === 'file') {
    //     callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
    //   }

    //   /* Provide image and alt text for the image dialog */
    //   if (meta.filetype === 'image') {
    //     callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
    //   }

    //   /* Provide alternative source and posted for the media dialog */
    //   if (meta.filetype === 'media') {
    //     callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
    //   }
    // },
    // init_instance_callback: function (editor) {
    //   editor.on('change', function (e) {
    //     var length = editor.contentDocument.body.innerHTML.length;
    //   });
    // },
    // templates: [
    //   { title: 'New Table', description: 'creates a new table', content: '<div class='mceTmpl'><table width='98%%'  border='0' cellspacing='0' cellpadding='0'><tr><th scope='col'> </th><th scope='col'> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
    //   { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
    //   { title: 'New list with dates', description: 'New List with dates', content: '<div class='mceTmpl'><span class='cdate'>cdate</span><br /><span class='mdate'>mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
    // ],
    // template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
    // template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
    image_caption: true,
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_noneditable_class: 'mceNonEditable',
    toolbar_mode: 'sliding',
    contextmenu: 'link table',
    language_url: '/assets/lt.js',
    language: 'lt',
    paste_data_images: false
  }

  small = false;

  public _news: any;
  newsEditForm: UntypedFormGroup;
  @Input() news;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  chgInterval: any;
  chgIntervalMilliseconds = 2000;
  tmpId: string;
  filesKeyPrefix: string;
  @ViewChild(UploadFileComponent) filesUploader: UploadFileComponent;
  public _files: any = [];
  filesPath = environment.apiUrl + 'files/';
  @Input() isLoading;
  calendarCustomHeaderComponent: any = CalendarCustomHeaderComponent;

  constructor(private formBuilder: UntypedFormBuilder,
    // private dateAdapter: DateAdapter<Date>, 
    private chg: ChangeDetectorRef, private _newsBackend: NewsBackend, private breakpointObserver: BreakpointObserver) {
    // dateAdapter.setLocale('lt-LT');
  }

  ngOnInit() {
    this._subscriptions.push(this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.small = true;
      } else {
        this.small = false;
      }
    }));
    this._news = this.news;
    this.tmpId = this._news ? this._news.newsId : 'tmp_' + new Date().getTime();
    this.filesKeyPrefix = 'news_' + this.tmpId;
    this.buildForm(this._news);

    if (this._news) {

      this._subscriptions.push(this._newsBackend.getFiles(this._news.newsId).subscribe((r: any) => {
        if (r.success) {
          this._files = r.entities;
          try {
            this.chg.detectChanges();
          } catch (err) { }
        }
      }));
    }
  }

  ngAfterViewInit() {

    this.initTinyMce();
    this.clearFiles();

  }

  dateChanged(el) {
    el.close && el.close();
  }

  initTinyMce() {
    if (typeof tinymce !== 'undefined' && document.getElementById('shortText') && document.getElementById('text')) {
      tinymce.init({
        selector: '#shortText',
        height: this.small ? 400 : 200,
        paste_as_text: true,
        ...this._htmlEditorConfig
      });
      tinymce.init({
        selector: '#text',
        height: this.small ? 600 : 400,
        paste_as_text: true,
        deprecation_warnings: false,
        ...this._htmlEditorConfig
      });
    } else {
      setTimeout(() => {
        this.initTinyMce();
      }, 1000);
    }
  }



  buildForm(news) {
    this.newsEditForm = this.formBuilder.group({
      title: [news ? news.title : null, Validators.required],
      shortText: [news ? news.shortText : null],
      text: [news ? news.text : null],
      status: [news && news.status === NEWS_STATUS_PUBLISHED.status ? true : false],
      private: [news && news.isPrivate ? true : false],
      showFrom: [news && news.showFrom ? new Date(news.showFrom) : new Date(), Validators.required],
    });
  }

  ngOnDestroy() {
    tinymce.remove('#shortText');
    tinymce.remove('#text');
    this._subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }

  save() {
    const showFrom = new Date(this.newsEditForm.controls.showFrom.value);
    showFrom.setMinutes(showFrom.getMinutes() - showFrom.getTimezoneOffset());
    const updateObj: any = {
      title: this.newsEditForm.controls.title.value,
      shortText: tinymce.get('shortText').getContent(),
      text: tinymce.get('text').getContent(),
      status: this.newsEditForm.controls.status.value,
      isPrivate: this.newsEditForm.controls.private.value,
      showFrom: showFrom,
      newsId: this._news && this._news.newsId,

    }
    if (!this._news) {
      delete updateObj.newsId
      updateObj.tempId = this.tmpId
    }
    this.saveEvent.emit(updateObj);
  }

  isInvalidForm() {
    if (this.newsEditForm.valid && !this.isLoading) {
      return false;
    }
    return true;
  }

  cancel() {
    this.cancelEvent.emit(true);
  }

  getFilesLinks(filesKey) {
    const filesObj: any = {
      filesKey: filesKey
    }
    if (this._news && this._news.newsId) {
      filesObj.newsId = this._news.newsId;
    } else {
      filesObj.tempId = this.tmpId;
    }
    this._subscriptions.push(this._newsBackend.addFiles(filesObj).subscribe((r: any) => {
      if (r.success) {
        this._files = r.entities;
        try {
          this.filesUploader.refresh();
          this.chg.detectChanges();
        } catch (err) { }
      }
    }));
  }

  copyToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = this.filesPath + text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
    }

    document.body.removeChild(textArea);
  }

  clearFiles() {
    try {
      this.filesUploader.refresh();
      this.chg.detectChanges();
    } catch (e) {

    }
  }

}
