import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../../models/User';
import { ApiService } from '../../core/api/api.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { TokenCheckService } from '../../token.check.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class UserService {
  public user$: Subject<User> = new BehaviorSubject(new User({}));
  private _user: User;
  public triggerMenuCheck$ = new Subject();
  public get user(): User {
    return this._user;
  }
  public set user(val: User) {
    this._user = val;
    this.user$.next(val);
  }

  permissionsLoaded = new Subject();

  constructor(private apiService: ApiService,
    private dialog: MatDialog,
    private authService: AuthService, private router: Router, private permissionsService: NgxPermissionsService, private tokenCheckService: TokenCheckService) {
    this.apiService.logout$.subscribe((noRedirect) => {
      this.logOut(noRedirect);
    });
  }

  setUser(user) {
    this.user$.next(new User(JSON.parse(user)));
  }

  logOut(noRedirect) {
    this.dialog.closeAll();
    this.authService.clearIdToken();
    this.authService.clearUser();
    this.authService.permissionsLoaded = false;
    this.permissionsService.flushPermissions();
    this.tokenCheckService.stopTokenChecks();
    const url = location.href;
    if (!noRedirect) {
      this.router.navigate(['/login'], { queryParams: { url } });
    }
  }

  login(username, password) {
    return this.apiService.postNoAuth('users/authenticate', { username, password });
  }

  recoverPassword(email) {
    return this.apiService.postNoAuth('users/resetPassword', { email });
  }

  changePassword(token, password) {
    return this.apiService.postNoAuth('users/forgotPassword', { token, password });
  }

  checkPasswordToken(token) {
    return this.apiService.postNoAuthNoRedirect('users/checkPasswordToken', { token }, { responseType: 'text' });
  }

  changeInitialPassword(ticket, ticketId, password) {
    return this.apiService.postNoAuth('users/changeInitialPassword', { ticket, ticketId, password });
  }

  getUserPermissions() {
    return this.apiService.getAuthNoUnauthRedirect('users/permissions');
  }
}
